import { Injectable } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators  } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { GlobalService } from './global.service';
import { ReferenceService } from './general/reference.service';
import { Observable, Subject  } from 'rxjs';
import * as moment from 'moment-timezone';
import { UserService } from './user.service';

//****************[Reference]***************************
// project_change - triggers if the project changes
  // sample use - project_change.subscribe(next => { //code here// })
        
// 
// 
// getUserDataConfig(setting_name, [callback]) - get user setting depends on the user loggedin
// updateUserDataConfig(setting_name, the_data(string/intiger/object/array)) - set user setting depends on the user loggedin
// 
// projectName(project_id) - get the project name, return with project name
// projectData(project_id) - get the project data, return with project data
// randomcolor() - return random color hex
// convertDT('2021-01-10','MM/DD/Y') - convert date - return converted date
// ****************************************************

@Injectable()
export class CommonService {
    ships: any = [];
    is_mobile: boolean = false;
    openchat: boolean = false;
    account_types: any = [];
    data: any = {};
    user_access: any = [];
    loaded_common: any = [];
    departments: any = [];
    locations: any = [];
    projectId: any = 0;
    shipCode: any = 'AA';
    shipCodeMultiple: any = [];
    module_code: any = '';
    page_title: any = 'i95';
    project_change = new Subject();
    ship_change = new Subject();
    message_change = new Subject();

    
    main_page_called: boolean = false;

    ssoUrl:string = this.config.appConfig.apiurl+'sso';   

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private globalservice: GlobalService,
    private referenceservice: ReferenceService,
    private userservice:UserService
    ) { }

  


  convertDT(dt:any, format:any='Y-M-D'){
    let d:any = dt+ '00:00:00';
    if(dt!='0000-00-00' && dt){
      // let tz = moment.tz.guess();
      if(moment(dt).format('HH:mm')=='00:00')
        d = moment(dt).format(format);            
      else
        d = moment.utc(dt).local().format(format);      
    }

    return d;
  }
  calendarDT(dt:any){
    let d:any = '';
    if(dt!='0000-00-00' && dt){
      d = moment(dt).utcOffset(0, true).calendar();
    }

    return d;
  }
  randomcolor(){
    return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
  }

  
  show_file_viewer: boolean = false;
  img_loading: boolean = false;
  show_file_url: any = '';
  show_file_type: any = '';
  
  openFile(id:any={},url: any = ''){
    this.img_loading = true;
    this.show_file_viewer = true;
    setTimeout(() => {
      this.show_file_url = id == 0 ? url : this.config.appConfig.apiurl+'upload/file/image/'+id;
    }, 300);
   }

  onImageLoad(){
    this.img_loading = false;
  }

  projectName(id:any=''){

    // if()
    let project_name:String = '';
    var project_data:any;
    if(id!='all'){
      if(!id){ 
        // this.data.projects
        project_data = this.globalservice.getData(this.data.projects, 'id',this.projectId);
      }else{
  
        project_data = this.globalservice.getData(this.data.projects, 'id',id);
      }
      if(project_data){
        project_name = project_data.project_name;
      }
    }else{
      project_name = 'ALL'
    }
    return project_name;
  }


  projectData(id:any=''){

    // if()
    let project_name:String = '';
    var project_data:any;
    if(id!='all'){
      if(!id){ 
        // this.data.projects
        project_data = this.globalservice.getData(this.data.projects, 'id',this.projectId);
      }else{
  
        project_data = this.globalservice.getData(this.data.projects, 'id',id);
      }
    }else{
      project_name = 'ALL'
    }
    return project_data;
  }


  user_profile: any = {};

  // import { GlobalService } from './global.service';

//   get_notification_count: (usr='', callBack) =>{

//     db.select('count(notification_id) as unread');
//     db.where('read', 0);
//     db.where('to'. usr);
//     db.get(socketservice.table, function(err,result){
//         if(err){
//             return callBack(err);
//         }
//         return callBack(null, result);
//     });
// }
 defaultCallback(t1:any,t2:any){
  // alert();
  // console.log(t2);
  // return {t1, t2};
 }

  loadCommon = (name:any = '', callBack:any = this.defaultCallback) => {
   

    var encships = localStorage.getItem('temp_'+name);
    if(encships==null && !this.loaded_common.includes(name)){
      
      this.loaded_common.push(name);
      this.data[name] = [];

       this.http.get<any>(this.config.getConfig().apiurl+'common/'+name).subscribe(e => {
            let data = e;
            // console.log('dd', e);
            if(data.success){
              this.data[name] = [];
                let enc = this.config.encrypt(JSON.stringify(data.data));
                localStorage.setItem('temp_'+name, enc);
                this.getCommon(name);
                return callBack(null, this.data[name]);
            }
          },(error) => {    
            return callBack(true, []);
          });  

    }else{
        this.getCommon(name);
        return callBack(null, this.data[name]);
    };
  }
  loadCommonAwait = (name:any = '') => {
   
    return new Promise<any>((resolve, reject) => {
      var encships = localStorage.getItem('temp_'+name);
      if(encships==null && !this.loaded_common.includes(name)){
        
        this.loaded_common.push(name);
        this.data[name] = [];

        this.http.get<any>(this.config.getConfig().apiurl+'common/'+name).subscribe(e => {
              let data = e;
              if(data.success){
                this.data[name] = [];
                  let enc = this.config.encrypt(JSON.stringify(data.data));
                  localStorage.setItem('temp_'+name, enc);
                  this.getCommon(name);
                  resolve(this.data[name])
              }
            },(error) => {    
              resolve([]);
            });  

      }else{
          this.getCommon(name);
          resolve(this.data[name])
      };
        

    });

 
  }

 async getCommon(name:any=''){
    var udata = localStorage.getItem('temp_'+name);
    if(udata!=null){
      // console.log('common_name',name, udata);
      var encdata = this.config.decrypt(udata);
      var parse = JSON.parse(encdata);
        // if(this.data[name]==undefined){
            this.data[name] = parse;
        // }
      return await this.data[name];
    } 
  }

  updateCommon(name:any = '',identifier: any = '', data: any = {}){
    
    var i:any = this.globalservice.getDataIndex(this.data[name], identifier, data[identifier]);
     if(i!=undefined){
      this.data[name][i] = data;
     }else{
      this.data[name].push(data);
     }

     let enc = this.config.encrypt(JSON.stringify(this.data[name]));
     localStorage.setItem('temp_'+name, enc);

  }
  updateAllCommon(name:any = '',data: any = {}){
    this.data[name] = data;
     let enc = this.config.encrypt(JSON.stringify(this.data[name]));
     localStorage.setItem('temp_'+name, enc);
  }

  removeCommon(name:any = '',identifier: any = '', value: any = ''){
    
    var i:any = this.globalservice.getDataIndex(this.data[name], identifier, value);
     if(i!=undefined){
      
      this.data[name].splice(i, 1); 
      let enc = this.config.encrypt(JSON.stringify(this.data[name]));
      localStorage.setItem('temp_'+name, enc);
  
    }


  }


  userName(id:any = 0){
    var name: any = '';
    if(id!=0 && this.data.users!=undefined){

      var user_data:any = this.globalservice.getData(this.data.users, 'id', Number(id));
      if(user_data){
        name = user_data.first_name+' '+user_data.last_name;
      }
    }
    return name;
  }
  userProfileData(id:any = 0){
    if(id!=0 && this.data.users!=undefined){
      var user_data:any = this.globalservice.getData(this.data.users, 'id', Number(id));
      if(user_data) return user_data
    }
    
    return {};
  }
  userProfilePicture(id:any = 0){
    var name: any = '';
    if(id!=0 && this.data.users!=undefined){

      var user_data:any = this.globalservice.getData(this.data.users, 'id', Number(id));
      if(user_data){
        if(user_data.userphotofile){
          return user_data.userphotofile;
        }else{
          return '/assets/img/common_avatar.svg';
        }
       }else{
        return '/assets/img/common_avatar.svg';
      }
    }
  }
  hasUserProfilePicture(id:any = 0): boolean{
    var name: any = '';
    if(id!=0 && this.data.users!=undefined){

      var user_data:any = this.globalservice.getData(this.data.users, 'id', Number(id));
      if(user_data){
        if(user_data.userphotofile){
          return true;
        }else{
          return false;
        }
       }else{
        return false;
      }
    }
    return false;
      
  }
  userAccountType(account_level:any = 0){
    var name: any = '';


      var user_data:any = this.globalservice.getData(this.data.users, 'id', Number(account_level));
      if(user_data){
        if(user_data.userphotofile){
          return user_data.userphotofile;
        }else{
          return '/assets/img/common_avatar.svg';
        }
       }else{
        return '/assets/img/common_avatar.svg';
      }
    }

  updateUserSettings(setting_name:any = ''){

    
    var user_settings:any = this.globalservice.getData(this.data.user_data, 'setting_name', setting_name);
    this.updateCommon('user_data', this.data.user_data[user_settings]);

    var save_data:any = user_settings.data;
    this.http.post<any>(this.config.getConfig().apiurl+'common/user_data', {setting_name: setting_name, data: save_data}).subscribe(e => {
          
    },(error) => {        
    
    });  
  }

  updateUserSettings2(setting_name:any = '', new_data:any=''){

    if(!this.data.user_data){
      this.data.user_data = [];
    }

    var push_data = {setting_name: setting_name, data:new_data};
    var user_settings:any = this.globalservice.getDataIndex(this.data.user_data, 'setting_name', setting_name);
    if(user_settings==undefined){
      this.data.user_data.push(push_data);
    }else{
      this.data.user_data[user_settings] = push_data;
    }
    this.data.user_data[setting_name] = new_data;
    // this.updateCommon('user_data', this.data.user_data);
    this.http.post<any>(this.config.getConfig().apiurl+'common/user_data', {setting_name: setting_name, data: new_data}).subscribe(e => {
          
    },(error) => {        
    
    });  
  }










  getUserDataConfig = (setting_name:string='', callBack:any) => {
    var return_data = false;

    this.loadCommon('user_data',(err:any,result: any) => {
      // console.log('result',result);
      let get_data:any= this.globalservice.getData(result,'setting_name',setting_name);
      if(get_data!=undefined){
        
        let resultdata = (get_data.data) ? get_data.data : null;
        if(resultdata){
          if(get_data.type=='object'){
            return_data = JSON.parse(resultdata);
          }else{
            return_data = resultdata;
          }
        }
      }


      return callBack(return_data);
    });


  }

  getUserDataConfigPromise = (setting_name:string='') => {
    
    return new Promise(resolve=>{

      this.loadCommon('user_data',(err:any,result: any) => {
        console.log('result',result);
        let return_data:any;
        let get_data:any= this.globalservice.getData(result,'setting_name',setting_name);
        if(get_data!=undefined){
          
          let resultdata = (get_data.data) ? get_data.data : null;
          if(resultdata){
            if(get_data.type=='object'){
              return_data = JSON.parse(resultdata);
            }else{
              return_data = resultdata;
            }
          }
        }
        resolve(return_data);
    });
    
    });


  }
  
  updateUserDataConfig(setting_name:any = '', new_data:any=''){
    var type = typeof(new_data);
    var updated_data = new_data;
    if(type=='object'){
      updated_data = JSON.stringify(new_data);
    }
    
    var push_data = {setting_name: setting_name, data:new_data };
    var user_settings:any = this.globalservice.getDataIndex(this.data.user_data, 'setting_name', setting_name);
    if(user_settings==undefined){
      this.data.user_data.push(push_data);
    }else{
      this.data.user_data[user_settings] = push_data;
    }
    this.updateCommon('user_data', push_data);
    // this.data.user_data[setting_name] = new_data;
    return new Promise(resolve => {
      let send_data =  {setting_name: setting_name, data: updated_data, type: type};
      this.http.post<any>(this.config.getConfig().apiurl+'common/user_data',send_data).subscribe(e => {
        resolve(e);    
      },(error) => {        
        resolve(error);
      });  
    });  
  }


  
  companyName(id:any = 0){
    var name: any = '';
    if(id!=0 && this.data.users!=undefined){

      var company_data:any = this.globalservice.getData(this.data.company, 'id', Number(id));
      if(company_data){
        name = company_data.company;
      }
      

    }
    return name;
  }

  addCommon(name:any = '', data: any = {}){

    
    var udata = localStorage.getItem('temp_'+name);


    if(udata!=null){
      var encdata = this.config.decrypt(udata);
      var parse = JSON.parse(encdata);
      var endata = parse.unshift(data);
      localStorage.setItem('temp_'+name, this.config.encrypt(endata));

        if(this.data[name]!=undefined){
            this.data[name].unshift(data);
        }
      // return  this.data[name];
    } 


  }



  loadShips(){
    var encships = localStorage.getItem('ships');

    if(encships==null){
        this.http.get<any>(this.config.getConfig().apiurl+'common/ships').subscribe(e => {
          
            let data = e;
            if(data.success){
                let enc = this.config.encrypt(JSON.stringify(data.data));
                localStorage.setItem('ships', enc);
                
                this.ships = this.getShips();
            }
        
          },(error) => {        
          });  

    }else{

        this.ships = this.getShips();
    };
  }


  loadAccountTypes(){

    var encat = localStorage.getItem('account_types');

    if(encat==null){
        this.http.get<any>(this.config.getConfig().apiurl+'common/account_types').subscribe(e => {
          
            let data = e;
            if(data.success){
                let enc = this.config.encrypt(JSON.stringify(data.data));
                localStorage.setItem('account_types', enc);
                
                this.account_types = this.getAccountTypes();
            }
        
          },(error) => {        
          });  

    }else{

        this.account_types = this.getAccountTypes();
    };
  }

  getShips(){
    
    var uships = localStorage.getItem('ships');

    if(uships!=null){
      var encprofile = this.config.decrypt(uships);
      var parse = JSON.parse(encprofile);
      this.ships = parse;
      return this.ships;
    }else{
      return null;
    }
    
  }
  getAccountTypes(){
    
    var uat = localStorage.getItem('account_types');

    if(uat!=null){
      var encprofile = this.config.decrypt(uat);
      var parse = JSON.parse(encprofile);
      this.account_types = parse;
      return this.account_types;
    }else{
      return null;
    }
    
  }

  getUser(id: any){
      return this.http.get<any>(this.config.getConfig().apiurl+'user/getuser/'+id); 
  }

  getDepartmentUsers(id:any){
    return this.http.get(this.config.getConfig().apiurl + 'departments/'+id+'/users');
  }


  inArrayData(arr: any = [], key:any = '',value: any = '') {
    var ret = false;
    arr.forEach(function(item: any){
      if ((item[key]).toLowerCase() === value.toLowerCase()){
            ret = true;
        }
    });

    return ret;
};
  inData(arr: any = [], key:any = '',value: any = '') {
    var ret;
    arr.forEach(function(item: any){
      if (item[key] != null && value != null){
        if (isNaN(item[key])){
          if ((item[key]).toLowerCase() === value.toLowerCase()) {
            ret = item;
          }
        }else{
          if (item[key] === value) {
            ret = item;
          }
        }
      }
    });

    return ret;
};

async inMultiArrayData(arr:any=[],data:any ={}){
  for (var i = 0; i < arr.length; i++) {
    if (JSON.stringify(arr[i]) === JSON.stringify(data)) {
      return true;
    }
  }
  return false;
}



  dropdownFormatter(data: [], _keyName: string, _keyId: string) {
    let dropdownValue:any = [];

    if (!data) return [];

    data.forEach((element: any) => {
      dropdownValue.push({ 'name': element[_keyName], 'code': element[_keyId] });
    });

    return dropdownValue;
  }



  getDefaultSeemore(module_code: any = ''){
    return this.http.get<any>(this.config.getConfig().apiurl+'general/seemore/'+module_code+'/default');

  }

  //check if the object is in the array
  inArrayKeyData(arr:any=[],key:any){
      let _ret:boolean = true;
      let _keys:any = [];

      arr.forEach((val:any)=>{
        let _key = Object.keys(val)[0];
        _keys.push(_key);
      });

      return _keys.includes(key);
  }

  changeProject(){
    this.project_change.next(this.projectId);
  }
  changeShipMultiple(){
    this.ship_change.next(this.shipCodeMultiple);
  }

  incomingMessage(data:any){
    this.message_change.next(data);
  }

  getReferenceValues(projectId: number, refid: number,moduleId: number) {
    return this.http.get(this.config.getConfig().apiurl + 'references' + "/" + refid + '/assigned/' + projectId +'/module/'+moduleId, {});
  }

  projectDetails(key='',id:any=''){
    let departmentId: number = 0;
    var project_data: any;
    if (!id) {
      project_data = this.globalservice.getData(this.data.projects, 'id', this.projectId);
    } else {
      project_data = this.globalservice.getData(this.data.projects, 'id', id);
    }

    if(key !=''){
      if(project_data==undefined){
        return undefined;
      }
      return project_data[key];
    }
    
    return project_data;
  }
  
  projectModule(module_id:any){
    return this.http.get<any>(this.config.getConfig().apiurl+'common/projectmodule/'+module_id);
  }

  projectModulePromise(module_id:any){

    
    return new Promise<any>((resolve, reject)=>{

        this.projectModule(module_id).subscribe((e:any)=>{
          let module_projects:any = []
          if(e.data){
            for(let d of e.data){
              module_projects.push(d.project_id);
            }
          }else{
            resolve([]);
          }
          resolve(module_projects);
        
        });
      
    })
  }

  // this will update the (-) parent id to newly created ID
  updateNewItemUpload(temp_id:any = 0, new_id:any=0,module_code:any=''){
    this.http.put<any>(this.config.getConfig().apiurl+'upload/update_parent/'+new_id, {module_code: module_code, temp_id: String(temp_id)}).subscribe(e => {
          
    },(error) => {        
    
    }); 
  }
    // this will update the (-) parent id to newly created ID
  updateNewItemComment(temp_id:any = 0, new_id:any=0,module_code:any=''){
    this.http.put<any>(this.config.getConfig().apiurl+'comments/update_parent/'+new_id, {module_code: module_code, temp_id: String(temp_id)}).subscribe(e => {
          
    },(error) => {        
    
    }); 
  }

  acronym(s:any=''){
    var words, acronym, nextWord;

    words = s.split(' ');
    acronym= "";
    let index = 0
    while (index<words.length) {
            nextWord = words[index];
            acronym = acronym + nextWord.charAt(0);
            index = index + 1 ;
    }
    return acronym
}

getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}


  projectRecount(project_id:any = 0){
    // console.log(this.project)
  }
 
  getAzureUsers(search:string=''){     
    return this.http.get<any>(this.ssoUrl+'/azure-users?search='+search);
  }

  changeShip(){
    this.ship_change.next(this.shipCode);
  }

  shipName(ship_code:string = ''){
    var name: any = '';
    if(ship_code!='' && this.data.ships!=undefined){

      var ships_data:any = this.globalservice.getData(this.data.ships, 'code', ship_code);
      if(ships_data){
        name = ships_data.name;
      }else{
        name = 'All Ships'
      }
    }
    return name;
  }

  getAssignedProjects(module_id:number){
    return new Promise(async(resolve)=>{
      let projects = await this.loadCommonAwait('projects');
      let user_access:any = localStorage.getItem('user_access_data');  
      let decrypted_user_access:any[] = JSON.parse(this.config.decrypt(user_access));
      let assigned_projects = decrypted_user_access.filter((e:any)=>e.project_id !== ''&& e.module_id === module_id);
      let module_projects:any = [];
      this.projectModule(module_id).subscribe((e:any)=>{
        if(e.data){
          for(let d of e.data){
            let checkInclude:any = assigned_projects.filter((e:any)=>e.project_id===d.project_id)[0];
            if(this.userservice.user_profile.position<=89){
              if(checkInclude){
                let _project_info = projects.filter((e:any)=>e.id === checkInclude.project_id);
                if(_project_info[0]){
                  module_projects.push(_project_info[0]);
                }
              } 
            }else{
              let _project_info = projects.filter((e:any)=>e.id === d.project_id);
              if(_project_info[0]){
                module_projects.push(_project_info[0]);
              }
            }      
          }
  
          resolve(module_projects);
        }
      });
    })
    
    
  }


}
