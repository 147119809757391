<!-- <div #hello (click)="appScope.test()">Hello !</div> -->

<!-- <ng-template #temp let-item>
    <div>
      This is my variable <strong>{{ item }}</strong> which is dynmically parsed.
    </div>
  </ng-template>
  
  <ng-container
    *ngTemplateOutlet="temp; context: { $implicit: name }"
  ></ng-container> -->
  

  <!-- <cng-html-compiler [template]="template"
                   [componentClass]="componentClass">
</cng-html-compiler> -->

<!-- currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"  -->



    
    <!--   -->

<!-- <cng-html-compiler 
    [template]="template"
    [componentClass]="componentClass">
</cng-html-compiler> -->



<!-- general filter -->

<!-- <cng-html-compiler [template]="template"
    [componentClass]="appScope">
</cng-html-compiler> -->



<ng-template #rowline let-column>

</ng-template>

<p-contextMenu [model]="context_item" #cm  appendTo="body"></p-contextMenu>


<button #scapegoat class="scapegoat"></button>



<p-dialog header="Comments" [(visible)]="open_comments" [modal]="true" [style]="{ width: 'auto' }" [draggable]="false" [resizable]="false">
 
</p-dialog>


<p-overlayPanel #commentpanel>
    <div class="comment_card no_shadow">
        
        <div class="comment-container" *ngFor="let col of all_comments">
            <div class="user">
              <div class="user-pic">
                <i class="pi pi-user"></i>
              </div>
              <div class="user-info">
                <span> {{commonservice.userName(col.user)}}</span>
                <p>{{dateFormatTZ(col.datetime, 'MMMM Do YYYY, hh:mm a')}}</p>
              </div>
            </div>
            <pre class="comment-content">
             {{col.comment}}
            </pre>
          </div>

    </div>
</p-overlayPanel>


<p-overlayPanel #op [style]="{'width': 'auto'}"  [showCloseIcon]="true" styleClass="remove-padding" appendTo="body">
  
    <ng-template pTemplate="content">
        <div class="comment_card">
            <div #comments class="comments" >

              <div class="comment-container" *ngFor="let col of parse_comments[selected_context.col.Id]">
                <div class="user">
                  <div class="user-pic">
                    <i class="pi pi-user"></i>
                  </div>
                  <div class="user-info">
                    <span> {{commonservice.userName(col.user)}}</span>
                    <p>{{dateFormatTZ(col.datetime, 'MMMM Do YYYY, hh:mm a')}}</p>
                  </div>
                </div>
                <pre class="comment-content">
                 {{col.comment}}
                </pre>
              </div>

            </div>
            
          
            <div class="text-box">
              <div class="box-container">
                <textarea placeholder="Add Comment here.." 
                  [(ngModel)]="comment_content"
                ></textarea>
                <p-button icon="pi pi-send" pTooltip="Send" tooltipPosition="right" [disabled]="!comment_content" styleClass="p-button-rounded" (click)="addComment()"></p-button>
          
                <div>
                </div>
              </div>
            </div>
          </div>
    </ng-template>

</p-overlayPanel>

<p-overlayPanel #cop [style]="{'width': 'auto'}"  styleClass="remove-padding" appendTo="body">
  
    <ng-template pTemplate="content">
       
    </ng-template>

</p-overlayPanel>

<!-- Result output template -->
<ng-template #columnresult let-column>
    
   <ng-container *ngIf="!column.col.cellTemplate" #cell>
       <ng-container [ngSwitch]="column.col.Type">
            <ng-container *ngSwitchCase="'azure-user'" >
                <span pTooltip="{{getAzureDisplayName(column.data_list[column.col.Id])}}" tooltipPosition="top" >
                    {{getAzureDisplayName(column.data_list[column.col.Id])}}
                </span>

            </ng-container>
            <ng-container *ngSwitchCase="'azure-user-multiple'" >
                <span pTooltip="{{getAzureUsersDisplayName(column.data_list[column.col.Id])}}" tooltipPosition="top" >
                    {{getAzureUsersDisplayName(column.data_list[column.col.Id])}}
                </span>

            </ng-container>
            <ng-container *ngSwitchCase="'user'" >
                <span pTooltip="{{commonservice.userName(column.data_list[column.col.Id])}}" tooltipPosition="top" >
                    {{commonservice.userName(column.data_list[column.col.Id])}}
                </span>

            </ng-container>
            <ng-container *ngSwitchCase="'date'">
                <span pTooltip="{{dateFormat(column.data_list[column.col.Id],column.col)}}" tooltipPosition="top" >
                    {{dateFormat(column.data_list[column.col.Id],column.col)}}
                </span>
            </ng-container>

            <!-- for images -->
            <ng-container *ngSwitchCase="'images'">
                <ng-container *ngFor="let img of imgThumbnail(column.data_list[column.col.Id])">
                    <img class="img-thumbnail" [src]="img" alt="">
                    
                </ng-container>
            </ng-container>


            <ng-container *ngSwitchCase="'switch'">

                <!-- <label class="switch">
                    <input type="checkbox">
                    <span class="slider"></span>
                  </label> -->
                <p-inputSwitch
                    [ngModel]="switchValue(column.data_list[column.col.Id])"
                    (click)="switchChanged(column.col, column.data_list, column.ri)"
                    *ngSwitchCase="'switch'"
                    styleClass="mt-1"
                    [disabled]="!column.col.enableCellEdit || !allowEdit"
                    [readonly]="true"
                    [trueValue]="column.col.trueValue.value"
                    [falseValue]="column.col.falseValue.value">
                </p-inputSwitch>
       
            </ng-container>
            <ng-container *ngSwitchCase="'color'">
                <p-colorPicker 
                    appendTo="body"
                    (onChange)="general_model=$event.value"
                    [disabled]="!column.col.enableCellEdit || !allowEdit"
                    [ngModel]="column.data_list[column.col.Id]"
                ></p-colorPicker>

       
            </ng-container>

            <ng-container *ngSwitchCase="'textarea'" >
                <pre pTooltip="{{column.data_list[column.col.Id]}}" tooltipPosition="top" >{{column.data_list[column.col.Id]}}</pre>
            </ng-container>

            <ng-container *ngSwitchCase="'select2'" >
                <span tooltipPosition="top">
                    {{getOptionValue(column)}}
                </span>
            </ng-container>

            <ng-container *ngSwitchCase="'select-multiple'" >
                <span tooltipPosition="top" pTooltip="{{getOptionValue(column)}}">
                    {{getOptionValue(column)}}
                </span>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <span pTooltip="{{column.data_list[column.col.Id]}}" tooltipPosition="top" class="{{column.col.innerClass}}">
                    {{column.data_list[column.col.Id]}}
                </span>
            </ng-container>
        </ng-container>
   </ng-container>

   <!-- <ng-container *ngIf="column.col.cellTemplate">
        <span pTooltip="{{ column.col.hideToolTip==true ? '' : column.data_list[column.col.Id]}}" tooltipPosition="top">
            <cng-html-compiler 
                [template]="column.col.cellTemplate"
                [componentClass]="{
                    appScope: appScope,
                    COL_FIELD: column.data_list[column.col.Id],
                    rowData: column.data_list,
                    rowColumn: column.col,
                    rowIndex: column.ri
                }">
            </cng-html-compiler>
        </span>
          
   </ng-container> -->
</ng-template>

<ng-template #columnresult2 let-column>

    <!-- <div  [pTooltip]="data_list.data[col.Id]?.value" tooltipPosition="top" class="p-element fill-cell {{data_list.data[col.Id]?.class}}" [style]="data_list.data[col.Id]?.style">{{data_list.data[col.Id]?.value}}</div> -->
                   

    
   <ng-container *ngIf="!column.col.cellTemplate" #cell>
       <ng-container [ngSwitch]="column.col.Type">
           
            <!-- for images -->
            <ng-container *ngSwitchCase="'images'">
                <ng-container *ngFor="let img of column.data_list.data[column.col.Id].value">
                    <img  (click)="commonservice.openFile(img)" class="img-thumbnail" [src]="configservice.appConfig.apiurl+'upload/file/thumbnail/'+img" alt="">
                </ng-container>
            </ng-container>
            <!-- for single image -->
            <ng-container *ngSwitchCase="'image'">
                <div class="img-container">
                    <img (click)="commonservice.openFile(0,configservice.appConfig.apiurl+column.col.bucket+column.data_list.data[column.col.Id].value)" *ngIf="column.col.bucket" class="img-thumbnail-borderless" [src]="configservice.appConfig.apiurl+column.col.bucket+column.data_list.data[column.col.Id].value" width="100%"  alt="{{column.data_list.data[column.col.Id].value}}">
                    <img (click)="commonservice.openFile(0,configservice.appConfig.apiurl+'ga_viewer/img/'+column.data_list.data[column.col.Id].value)" *ngIf="!column.col.bucket" class="img-thumbnail-borderless" [src]="configservice.appConfig.apiurl+'ga_viewer/img/'+column.data_list.data[column.col.Id].value" width="100%"  alt="{{column.data_list.data[column.col.Id].value}}">
                    <div class="img-preview" (click)="commonservice.openFile(0,configservice.appConfig.apiurl+column.col.bucket+column.data_list.data[column.col.Id].value)">
                        <div class="img-preview-icon"><i class="pi pi-eye"></i></div>
                    </div>
                </div>
            </ng-container>


            
            <ng-container *ngSwitchCase="'buttons'">

                <div class="p-element fill-cell btn-container {{column.data_list.data[column.col.Id].class}}">
                 <ng-container *ngFor="let btn of column.col.buttons; index as i">
                        <!-- <button  class="btn {{btn.class ? btn.class :'btn-primary'}} btn-round btn-sm  position-relative mr-2" (click)="btn.command({rowdata: value[column.ri], rowindex: column.ri})"> 
                            <i class="{{btn.icon}}"></i>
                            {{btn.label ? btn.label : ''}}
                           
                            <div class="btn-badge" *ngIf="btn.badge && value[column.ri][btn.badge] > 0">{{ value[column.ri][btn.badge] ? value[column.ri][btn.badge] : 0 }}</div>  
                        </button> -->
                        <div class="btn-container-inner">
                            <button  
                                [pTooltip]="column.data_list.data[column.col.Id].btn_class[i].tooltip" 
                                tooltipPosition="top" pButton type="button" [label]="btn.label" [disabled]="column.data_list.data[column.col.Id].btn_class[i].disabled" [icon]="btn.icon" class="i95-tble-btns p-button-sm {{column.data_list.data[column.col.Id].btn_class[i].class}}" (click)="btn.command({rowdata: value[column.ri], rowindex: column.ri})">
                            </button>
                             <div class="btn-badge" *ngIf="btn.badge && value[column.ri][btn.badge] > 0">{{ value[column.ri][btn.badge] ? value[column.ri][btn.badge] : 0 }}</div>  
              
                        </div>

  
                    </ng-container>
                              </div>

            </ng-container>

     

            <ng-container *ngSwitchDefault>

                <div [pTooltip]="removeTags(column.data_list.data[column.col.Id].value)" 
                [tooltipStyleClass]="column.col?.toolTipClass" tooltipPosition="top"  class="p-element fill-cell {{column.data_list.data[column.col.Id].class}}" [style]="column.data_list.data[column.col.Id].style"
                [innerHTML]="column.data_list.data[column.col.Id].value"
                ></div>
  

                <!-- <div 
                [pTooltip]="column.data_list.data[column.col.Id].value" 
                [tooltipStyleClass]="column.col?.toolTipClass" 
                tooltipPosition="top"  
                class="p-element fill-cell {{column.data_list.data[column.col.Id].class}}" 
                [style]="column.data_list.data[column.col.Id].style"
                [innerHTML]="column.data_list.data[column.col.Id].value"
                ></div> -->
  

            </ng-container>
        </ng-container>
   </ng-container>


   <!-- <ng-container *ngIf="column.col.cellTemplate">
    <span class="p-element fill-cell {{column.data_list.data[column.col.Id].class}}">
        <cng-html-compiler 
            [template]="column.col.cellTemplate"
            [componentClass]="{
                appScope: appScope,
                COL_FIELD: column.data_list.data[column.col.Id].value,
                rowData: value[column.ri],
                rowColumn: column.col,
                rowIndex: column.ri
            }">
        </cng-html-compiler>
    </span>
      
</ng-container> -->

   
</ng-template>



<!-- EDIT Row Template -->
<ng-template #columnedit let-column>

   
   <ng-container [ngSwitch]="column.col.Type">

    
            <i95-user-dropdown-wizard
                *ngSwitchCase="'user'"
                [userControlName]="column.col.Id"
                [showClear]="column.col.showClear"
                [userModel]="general_model"
                [allowed_users]="(reference[column.col.Id]) ? reference[column.col.Id] : false"
                (userModelChange)="general_model = $event"
                Type="dropdown"
                appendTo="body">
            </i95-user-dropdown-wizard>

            <azure-input-search
                *ngSwitchCase="'azure-user'"
                styleClass="form-control col-sm-12"
                (selectAzureUserEvent)="general_model =$event"
                [model]="general_model"
                [viewType]="'table'"
            >
            </azure-input-search>

            <azure-multiple-selection
            *ngSwitchCase="'azure-user-multiple'"
            styleClass="form-control col-sm-12"
            (selectAzureUserEvent)="general_model =$event"
            [model]="general_model"
            [viewType]="'table'"
        >
        </azure-multiple-selection>



        <p-multiSelect 
            *ngSwitchCase="'select-multiple'"
            [options]="general_option"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="{{column.col.Label}}"
            [filter]="true"
            [filterBy]="column.col.OptionLabel"
            appendTo="body"
            [optionLabel]="(column.col.OptionLabel) ? column.col.OptionLabel : '' "
            [optionValue]="(column.col.OptionValue) ? column.col.OptionValue : '' "
            [(ngModel)]="general_model"
            autoWidth="false"
            [style]="{'width':'100%'}">
        </p-multiSelect>


            <p-dropdown 
                *ngSwitchCase="'select2'"
                [options]="general_option"
                [virtualScroll]="true"
                [itemSize]="30"
                placeholder="{{column.col.Label}}"
                [filter]="true"
                [filterBy]="column.col.OptionLabel"
                appendTo="body"
                [optionLabel]="(column.col.OptionLabel) ? column.col.OptionLabel : '' "
                [optionValue]="(column.col.OptionValue) ? column.col.OptionValue : '' "
                [(ngModel)]="general_model"
                autoWidth="false"
                [showClear]="column.col.showClear"
                [style]="{'width':'100%'}">
            </p-dropdown>

            <p-calendar
                *ngSwitchCase="'date'"
                autoWidth="false"
                appendTo="body"
                [(ngModel)]="general_model"
                [readonlyInput]="true"
                [showButtonBar]="true"
                [style]="{'width':'100%'}">
            </p-calendar>

            <p-inputNumber 
                *ngSwitchCase="'number'"
                [(ngModel)]="general_model" 
                (keydown)="editKeyDown($event)"
                [allowEmpty]="true" 
                [useGrouping]="false" >
            </p-inputNumber>


            <textarea
                *ngSwitchCase="'textarea'"
                rows="3" cols="30"
                (keydown)="editKeyDown($event)"
                [(ngModel)]="general_model" 
                pInputTextarea>
            </textarea>

                <p-inputSwitch
                    [ngModel]="switchValue(column.data_list[column.col.Id])"
                    (click)="switchChanged(column.col, column.data_list, column.ri)"
                    *ngSwitchCase="'switch'"
                    styleClass="mt-1"
                    [disabled]="!column.col.enableCellEdit || !allowEdit"
                    [readonly]="true"
                    [trueValue]="column.col.trueValue.value"
                    [falseValue]="column.col.falseValue.value">
                </p-inputSwitch>

                <p-colorPicker 
                    *ngSwitchCase="'color'"
                    appendTo="body"
                    (onChange)="general_model=$event.value"
                    [disabled]="!column.col.enableCellEdit || !allowEdit"
                    [ngModel]="column.data_list[column.col.Id]"
                ></p-colorPicker>



            <textarea 
                *ngSwitchCase="'input'"
                pInputTextarea
                class="inputtext"
                (keydown)="editKeyDown($event)"
                type="text"
                [(ngModel)]="general_model">
            </textarea>


   </ng-container>

</ng-template>



<div *ngIf="general_filter.length > 0" class="general_filter">
    
    <div *ngIf="general_search.length > 0" class="filter-search filter-col">
        <input type="text" pInputText placeholder="Search here..." [(ngModel)]="filter.search" class="w-100" (keyup)="filter.changeSearch($event)"/>
        <i class="pi pi-times filter-remove-btn" *ngIf="filter.search" (click)="filter.search='';filter.executeSearch('')"></i>
    </div>

    <ng-container *ngFor="let col of general_filter" >

        <ng-container [ngSwitch]="col.Type">




            <div  class="filter-col" >


                <ng-container *ngSwitchCase="'select2'">

                    <p-dropdown
                        [options]="col.inGeneralFilter.options"
                        [virtualScroll]="true"
                        [itemSize]="30"
                        [filter]="true"
                        [filterBy]="col.OptionLabel"
                        appendTo="body"
                        [placeholder]="col.Label"
                        [showClear]="true"
                        [(ngModel)]="filter.value[col.Id]"
                        (onChange)="filter.change(col, $event.value)"
                        [style]="{'width':'100%'}">
                </p-dropdown>

                </ng-container>
    

                <ng-container *ngSwitchCase="'switch'">
                            <p-dropdown
                                [options]="[{label:col.trueValue.label, value: col.trueValue.value}, {label:col.falseValue.label, value: col.falseValue.value}]"
                                [virtualScroll]="true"
                                [itemSize]="30"
                                [filter]="true"
                                filterBy="name"
                                appendTo="body"
                                [placeholder]="col.Label"
                                [showClear]="true"
                                [(ngModel)]="filter.value[col.Id]"
                                (onChange)="filter.change(col, $event.value)"
                                [style]="{'width':'100%'}">
                            </p-dropdown>
                </ng-container>

                <ng-container *ngSwitchCase="'user'">
                    
                    <i95-user-dropdown-wizard
                            [userControlName]="col.Id"
                            [showClear]="true"
                            [allowed_users]="col.inGeneralFilter.options"
                            [userModel]="filter.value[col.Id]"
                            [placeholder]="col.Label"
                            Type="dropdown"
                            (userModelChange)="filter.change(col, $event)"
                            appendTo="body">
                    </i95-user-dropdown-wizard>

                </ng-container>

                
                <ng-container *ngSwitchCase="'date'">
                    <p-calendar
                        autoWidth="false"
                        appendTo="body"
                        [(ngModel)]="filter.value[col.Id]"
                        [readonlyInput]="true"
                        [showButtonBar]="true"
                        [placeholder]="col.Label"
                        (onSelect)="filter.change(col, filter.value[col.Id])"
                        (onClearClick)="filter.change(col, '')"
                        [style]="{'width':'100%'}">

                        <ng-template pTemplate="date" let-date>
                            <span [ngClass]="{'in_date': filter.inDate(col, date)}">{{date.day}}</span>
                        </ng-template>

                    </p-calendar>
                    <i class="pi pi-times filter-remove-btn" *ngIf="filter.value[col.Id]" (click)="filter.change(col, '')"></i>

        </ng-container>


            </div>
            <!-- <ng-container *ngSwitchCase="'switch'">
                <div *ngIf="general_search.length > 0" class="filter-col" >
                    <input type="text" pInputText [placeholder]="col.Label" class="w-100"/>
                </div>
            </ng-container> -->


        </ng-container>

        
        
    </ng-container>





    <div class="filter-btns">
        <button pButton type="button" label="Clear" class="p-button-sm p-button-danger p-button-rounded" (click)="filter.clear()"></button>
        <button pButton type="button" icon="fa fa-refresh" class="p-button-sm p-button-success p-button-rounded ml-1" (click)="filter.refresh()"></button>
    </div>


</div>





<div style="float:left; width: 100%; position: relative;"  *ngIf="!commonservice.is_mobile">
    

    <div style="position: absolute;top: 125px;width: 100%;" *ngIf="value.length<=0">
        <h2 class="mt-3 text-center">
            {{no_data_msg}}
         </h2>
    </div>

    
    <p-table
        (onColResize)="onResizeColumn($event)"
        #i95table
        *ngIf="display_table"
        [(contextMenuSelection)]="selected_context" 
        [contextMenu]="cm" 
        [value]="display_value"
        [scrollHeight]="(table_height-50)+'px'"
        [style]="{height: table_height+'px'}"
        [styleClass]="styleClass +' i95-table'"
        (onEditInit)="onEditInit($event)"
        (onEditComplete)="onEditComplete($event)"
        [rowHover]="true"
        [loading]="loading"
        [totalRecords]="totalRecords"
        [paginator]="(!hidePagination) ? true : false"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} to {last} of {totalRecords} entries"
        [rows]="rows"
        [rowsPerPageOptions]="rowsPerPageOptions"
        (onLazyLoad)="getList($event, i95table)"
        [lazy]="true"
        responsiveLayout="stack"
        [scrollable]="(!commonservice.is_mobile)"
        [autoLayout]="false"
        [responsive]="true"
        scrollDirection="both"
        [sortField]="sortfield"
        [sortOrder]="sortorder"
        [(selection)]="selected_item"
        dataKey="id"
        (onSort)="onChange($event, 'sort')"
        (onFilter)="onChange($event, 'filter')"
        (onScroll)="onChange($event)"
        (onPage)="onPageChange($event)"
        [virtualScroll]="(disable_virtual_scroll) ? false : true" 
        [virtualRowHeight]="virtual_row_height"
        (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowSelect($event)"  
        columnResizeMode="expand" 
        [resizableColumns]="resizeColumn"
    
    >
    <!-- [virtualScroll]="(value.length>100 || value.length==0) ? true : false" [virtualRowHeight]="40" -->
    <!-- [virtualScroll]="true" [virtualRowHeight]="10" -->
    

    
        <!-- TABLE HEADER -->
    <ng-template pTemplate="header">
        <tr class="head-label" 
        
        
        (mouseover)="commentpanel.hide()"
        >
    
            <th class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection">
                <p-checkbox
                [binary]="true"
                [(ngModel)]="header_checked"
                (ngModelChange)="checkAll($event)"
                ></p-checkbox>
            </th>
    
    
    
            <ng-container *ngFor="let col of fields" >
                
                


                <th 
                    pResizableColumn
                    pSortableColumn="{{col.Id}}"
                    *ngIf="allowShowField(col.Id)  && !col.hideField && view_loaded"
                    class="{{ (col.headerCellClass) ? col.headerCellClass : '' }} p-1 m-0"
                    pFrozenColumn
                    style="padding:0px !important;"
                    [accessKey]="col.Id"
                    [alignFrozen]="(col.alignFrozen) ? col.alignFrozen : 'right'"  
                    [frozen]="col.frozen"
                    [style]="columnStyle(col)"
                    >
                    <span *ngIf="!col.cellHeaderTemplate" class="p-2">
                        {{col.Label}}
                    </span>

                    <span  *ngIf="col.cellHeaderTemplate" class="fill-cell" [innerHTML]="col.cellHeaderTemplate">
                        
                        
                    </span>


                    <!-- <span  *ngIf="col.cellHeaderTemplate" class="fill-cell">
                        <cng-html-compiler 
                       
                        [template]="col.cellHeaderTemplate"
                        [componentClass]="{
                            appScope: appScope,
                            rowColumn: col
                        }">
                    </cng-html-compiler>
                    </span> -->

                    
                </th>
            </ng-container>
    
            <!-- <th>Action</th> -->
            <th class="small-width text-center" *ngIf="showAction" alignFrozen="right" pFrozenColumn [frozen]="true">
                <span class="p-frozen-col-inner">Action</span>
            </th>
    
        </tr>
    
    
        <tr *ngIf="allowFilter"  class="head-sort">
    
    
            <th class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection">
                <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
            </th>
    
    
    
    
       <!-- TABLE FILTER -->
            <ng-container *ngFor="let col of fields" >
                <th
                pFrozenColumn
                [alignFrozen]="(col.alignFrozen) ? col.alignFrozen : 'right'"  
                [frozen]="col.frozen"
                *ngIf="allowShowField(col.Id)  && !col.hideField && view_loaded"
                class="{{ (col.headerCellClass) ? col.headerCellClass : '' }}"
                [style]="columnStyle(col)"
                [ngSwitch]="col.Type">
    
                    <div  *ngIf="!col.removeFilter" class="filter-container-field">
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'user'">
                                            <ng-template pTemplate="filter">
    
                                                <i95-user-dropdown-wizard
                                                    [userControlName]="col.Id"
                                                    [showClear]="true"
                                                    [Type]="(col.multiFilter) ? 'multiple' : 'dropdown'"
                                                    [allowed_users]="(getOptions(col).length>0) ? getOptions(col) : false"
                                                    [userModel]="event.filters?event.filters[col.Id].value:''"
                                                    Type="dropdown"
                                                    (userModelChange)="filterChange(col, $event)"
                                                    appendTo="body">
                                                </i95-user-dropdown-wizard>
                                            </ng-template>
                        </p-columnFilter>
    
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'select2'">
                                            <ng-template pTemplate="filter">
    
                                                <p-dropdown
                                                    *ngIf="!col.multiFilter && show_dropdowns"
                                                    [options]="getOptions(col)"
                                                    [virtualScroll]="true"
                                                    [itemSize]="30"
                                                    placeholder="{{col.Label}}"
                                                    [filter]="true"
                                                    [filterBy]="col.OptionLabel"
                                                    appendTo="body"
                                                    [optionLabel]="(col.OptionLabel) ? col.OptionLabel : '' "
                                                    [optionValue]="(col.OptionValue) ? col.OptionValue : '' "
                                                    [(ngModel)]="event.filters[col.Id].value"
                                                    autoWidth="false"
                                                    (onChange)="filterChange(col, event.filters[col.Id].value)"
                                                    [showClear]="true"
                                                    [style]="{'width':'100%'}">
                                                </p-dropdown>

                                                <p-multiSelect
                                                    *ngIf="col.multiFilter && show_dropdowns"
                                                    [options]="getOptions(col)"
                                                    [virtualScroll]="true"
                                                    [itemSize]="30"
                                                    placeholder="{{col.Label}}"
                                                    [filter]="true"
                                                    [filterBy]="col.OptionLabel"
                                                    appendTo="body"
                                                    [optionLabel]="(col.OptionLabel) ? col.OptionLabel : '' "
                                                    [optionValue]="(col.OptionValue) ? col.OptionValue : '' "
                                                    autoWidth="false"                                                    
                                                    [(ngModel)]="event.filters[col.Id].value"
                                                    (onChange)="filterChange(col, event.filters[col.Id].value)"
                                                    [style]="{'width':'100%'}">
                                                </p-multiSelect>
    
    
                                            </ng-template>
                        </p-columnFilter>
    
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'switch'">
                                            <ng-template pTemplate="filter">
    
                                                <p-dropdown
                                                    *ngIf="show_dropdowns"
                                                    [options]="[{label:'All', value: null}, {label:col.trueValue.label, value: col.trueValue.value}, {label:col.falseValue.label, value: col.falseValue.value}]"
                                                    [virtualScroll]="true"
                                                    [itemSize]="30"
                                                    [filter]="true"
                                                    filterBy="name"
                                                    appendTo="body"
                                                    [(ngModel)]="event.filters[col.Id].value"
                                                    autoWidth="false"
                                                    (onChange)="filterChange(col, event.filters[col.Id].value)"
                                                    [showClear]="false"
                                                    [style]="{'width':'100%'}">
                                            </p-dropdown>
    
    
                                            </ng-template>
                        </p-columnFilter>
    
                        <p-columnFilter [field]="col.Id" matchMode="equals" [showMenu]="false" *ngSwitchCase="'date'" >
                                            <ng-template pTemplate="filter">
    
                                                <p-calendar
                                                    autoWidth="false"
                                                    appendTo="body"
                                                    [(ngModel)]="custom_filter[col.Id]"
                                                    [readonlyInput]="true"
                                                    [showButtonBar]="true"
                                                    (onSelect)="filterChange(col, custom_filter[col.Id])"
                                                    (onClearClick)="filterChange(col, '')"
                                                    [style]="{'width':'100%'}">
                                                </p-calendar>
    
    
                                            </ng-template>
                        </p-columnFilter>
    
    
                        <p-columnFilter matchMode="contains" type="text" [field]="col.Id" [showMenu]="false" *ngSwitchDefault></p-columnFilter>

                        <span *ngIf="filterIndicator(col)" class="filter-indicator">{{filterIndicator(col)}}</span>
                    </div>
    
                </th>
            </ng-container>


            <th class="small-width text-center p-fc-right force-right" *ngIf="showAction" alignFrozen="right" pFrozenColumn [frozen]="true" >
                <span class="p-frozen-col-inner">  </span>
            </th>
    
            <!-- <th class="small-width text-center p-fc-right" *ngIf="showAction" alignFrozen="right" pFrozenColumn [frozen]="true"></th> -->
    
        </tr>
    
    
    </ng-template>

    <!-- TABLE BODY -->
    
    <ng-template pTemplate="body" let-data_list let-ri="rowIndex"  >


        
        
        <tr  [ngClass]="data_list.rowClass" (dblclick)="rowDoubleClick(value[ri-event.first])">
    
            <td class="table-chckbox" alignFrozen="left"  pFrozenColumn [frozen]="true" *ngIf="selection && !commonservice.is_mobile">
                <p-tableCheckbox [value]="value[ri-event.first]" [disabled]="value[ri-event.first]?.disable_select" ></p-tableCheckbox>
            </td>
        
            <ng-container *ngFor="let col of fields;"   >
                <td
                    #cellitem
                    (click)="setCellELement(cellitem,data_list.data,col,ri)"
                    pFrozenColumn
                    [alignFrozen]="(col.alignFrozen) ? col.alignFrozen : 'right'"  
                    [frozen]="col.frozen"
                    [style]="columnStyle(col)"
                    class="{{ (col.cellClass) ? col.cellClass : '' }}"
                    pEditableColumn 
                    *ngIf="allowShowField(col.Id)  && !col.hideField && view_loaded"
                    [ngClass]="{'is_not_mobile_col': !col.is_mobile, 'd-block': commonservice.is_mobile, 'td-content-body': (col.cellTemplate && !col.frozen), 'have-comment': data_list.data[col.Id].comments}"
                    [ngSwitch]="col.Type"
                    [pEditableColumn]="value[ri-event.first]"
                    [pEditableColumnField]="col"
                    (contextmenu)="openContext(op, $event)"
                    (mouseover)="(data_list.data[col.Id].comments) ? showComments2(data_list.data[col.Id].comments, $event) : commentpanel.hide();"
                    [pContextMenuRow]="{col:col, data: value[ri-event.first], index: ri-event.first}"
                    [pEditableColumnRowIndex]="ri-event.first">
    

                    
                    <!-- (mouseout)="show_all_comments=false; commentpanel.hide()" -->
                    <!-- <span class="p-column-title">{{col.Label}}</span> -->
                    <span class="p-column-value fill-cell">
                        
                        
                        <ng-container>
                            <p-cellEditor *ngIf="col.enableCellEdit && allowEdit" >

                                <ng-template pTemplate="input" >
                                    <ng-template 
                                        *ngTemplateOutlet="columnedit; context: {$implicit: {data_list: value[ri-event.first], col: col, ri:ri-event.first}}" >
                                    </ng-template>
                                </ng-template>

                                <ng-template pTemplate="output">
                                    <ng-template 
                                        *ngTemplateOutlet="columnresult2; context: {$implicit: {data_list: data_list, col: col, ri:ri-event.first}}" >                                        
                                    </ng-template>
                                    <!-- <div [pTooltip]="data_list.data[col.Id].value" tooltipPosition="top"  class="p-element fill-cell {{data_list.data[col.Id].class}}" [style]="data_list.data[col.Id].style">{{data_list.data[col.Id].value}}</div> -->
                   
                                </ng-template>

                            </p-cellEditor>

                            <ng-container *ngIf="!col.enableCellEdit || !allowEdit">
                                <ng-template 
                                    *ngTemplateOutlet="columnresult2; context: {$implicit: {data_list: data_list, col: col, ri:ri-event.first}}" >
                                </ng-template>
                                <!-- <div [pTooltip]="data_list.data[col.Id].value" tooltipPosition="top"  class="p-element fill-cell {{data_list.data[col.Id].class}}" [style]="data_list.data[col.Id].style">{{data_list.data[col.Id].value}}</div> -->
                   
                            </ng-container>
                        


                        
                        </ng-container>
                    
                    </span>
                </td>
    
            </ng-container>
    
            <td class="small-width text-center p-fc-right" *ngIf="showAction" alignFrozen="right"  pFrozenColumn [frozen]="true">
                <span class="p-frozen-col-inner">
                    <button type="button" icon="pi pi-check" class="p-button-rounded p-button-outlined p-ripple p-button p-component p-button-icon-only"  (click)="openMenu(value[ri-event.first], $event)"  >
                        <i class="fa  fa-ellipsis-v"></i>
                        <span aria-hidden="true" class="p-button-label">&nbsp;</span>
                    </button>
    
               </span>
            </td>
        </tr>


    </ng-template>


    <!-- <ng-template pTemplate="emptymessage" let-data_list>

    </ng-template>
     -->

    <ng-template pTemplate="paginatorleft">
        <!-- <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button> -->
    </ng-template>


    <ng-template pTemplate="paginatorright"   >
        <button
        pButton
        pRipple
        type="button"
        icon="pi pi-refresh"
        pTooltip="Refresh"
        tooltipPosition="bottom"
        (click)="refreshTable()"
        label=""
        class="p-button-raised p-button-primary p-button-outlined ml-2">
    </button>





        
        <div *ngIf="!loading && commonservice.is_mobile" class="w-100">
            <div class="pull-left w-100">
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-angle-left"
                    [disabled]="!event.first"
                    (click)="changePage('-')"
                    pTooltip="Previous"
                    tooltipPosition="top"
                    label="Previous"
                    class="p-button-raised p-button-primary p-button-outlined pull-left">
                </button>
    
            <span class="mr-2 ml-1">
    
                <!-- <input type="number" pInputText
                    [(ngModel)]="pageValue"
                    [max]="totalPage()"
                    min=1
                > -->
    
            <p-inputNumber styleClass="short-width-input" [(ngModel)]="pageValue" [min]="1" [useGrouping]="false" [max]="totalPage()"></p-inputNumber>
            <!-- <p-dropdown [options]="totalPage(true)" [(ngModel)]="pageValue" (onChange)="onChangePage($event)"></p-dropdown> -->
            / {{totalPage()}}
            </span>
    
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-angle-right"
                    pTooltip="Next"
                    tooltipPosition="top"
                    (click)="changePage('+')"
                    [disabled]="totalPage()==page"
                    iconPos="right"
                    label="Next"
                    class="p-button-raised p-button-primary p-button-outlined pull-right">
                </button>
            </div>
        </div>


        


    </ng-template>
    
    
    </p-table>

    <div *ngIf="!display_table" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">
        <div class="loader">
            <p class="heading">Retrieving data. Please wait.</p>
            <div class="loading">
              <div class="load"></div>
              <div class="load"></div>
              <div class="load"></div>
              <div class="load"></div>
            </div>
          </div>
    </div>
</div>

<div class="mobile_card_container"  *ngIf="commonservice.is_mobile && display_table" style="height: {{table_height}}px;">


 
    <cdk-virtual-scroll-viewport  [itemSize]="100" class="virtual-scroll-container"  (scroll)="onScrollMobile($event)">

    
        <ng-container  *cdkVirtualFor="let item of value"  >
            <div class="mobile_card">
                <div class="mobile_card_inner">
                    <div class="mobile_card_title">Shipment id</div>
                    <div class="mobile_card_stat">Status</div>
                    <div class="mobile_card_desc">Description Description Description Description Description Description Description Description DescriptionDescription Description Description Description Description Description Description Description DescriptionDescription Description Description Description Description Description Description Description DescriptionDescription Description Description Description Description Description Description Description Description </div>
                    <div class="mobile_card_date">
                        <i class="fa fa-calendar"></i> 10/30/2024
                    </div>
                    <div class="mobile_card_user">
                        <i class="fa fa-user-o"></i> Lester
                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="loading">
            <div class="mobile_card"> <span class="card-loader"></span></div>
            <div class="mobile_card"> <span class="card-loader"></span></div>
            <div class="mobile_card"> <span class="card-loader"></span></div>
            <div class="mobile_card"> <span class="card-loader"></span></div>
        </ng-container>



    </cdk-virtual-scroll-viewport>




    
</div>

