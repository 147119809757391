import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { CommonService } from 'src/app/services/common.service';
import { GlobalService } from 'src/app/services/global.service';
import * as moment from 'moment';
import { DividerModule } from 'primeng/divider';
import { SkeletonModule } from 'primeng/skeleton';
import { CardModule } from 'primeng/card';
import { AvatarModule } from 'primeng/avatar';
import { NgIf, NgFor } from '@angular/common';




@Component({
    selector: 'i95-module-audittrail',
    templateUrl: './module-audittrail.component.html',
    styleUrls: ['./module-audittrail.component.scss'],
    standalone: true,
    imports: [NgIf, AvatarModule, CardModule, SkeletonModule, NgFor, DividerModule]
})
export class ModuleAudittrailComponent implements OnInit {
  audit_trail: any = {};
  loader: boolean = false;
  
  @Input() parent_id: any = 0;
  @Input() module_code: any = 'general';
  @Input() type: any = 'form';
  @Input() fields: any = 'form';
  
  url:any =  this.configservice.appConfig.apiurl+'audittrail/';
  constructor(
    public configservice: ConfigService,
    private http: HttpClient,
    public commonservice: CommonService,
    private globalservice: GlobalService,
  ) { }

  ngOnInit(): void {

    this.audit_trail = {};
    if(this.parent_id>0){
      this.callTrail();
    }
  }

createdInnerHtml(data:any = {}){

}

trailMessage(data:any = {}){
  // console.log('data',data);
  // console.log('field',this.fields);
  // let field_type:any = (this.fields.Type) ? this.fields.Type : 'any';
  
//   <div class="trail-item" >
//   <b>{{commonservice.userName(item_data.modified_by)}}</b>&nbsp; <span ></span> at <small>{{momentTime(item_data.modified_date)}}</small>
// </div>
  let field:any = this.globalservice.getData(this.fields, 'Id', data.field_name);
  let msg = '';
  if(field){

    if(field.Type == 'image'){
      msg = `<div class="trail-item-inner" ><b>${this.commonservice.userName(data.modified_by)}</b> changed image
      <b>${field.Label}</b> 
      `;
    }else{
      msg = `<div class="trail-item-inner" ><b>${this.commonservice.userName(data.modified_by)}</b> updated field
      <b>${field.Label}</b> 
      `;
    }


  
    if(field.Type=='date'){
      let from:any = (String(data.old_value)!='0000-00-00' && data.old_value) ? moment(data.old_value).format('MM/DD/Y') : '';
      let to:any = (String(data.new_value)!='0000-00-00' && data.new_value) ? moment(data.new_value).format('MM/DD/Y') : '';
      msg += ` from <em>"<u>${from}</u>"</em> to <em>"<u>${to}</u>"</em>`;
    }else if(field.Type=='azure-user-multiple'){
      let from:any = '';
      let to:any = '';

      if(data.old_value){
        let dataparse:any = JSON.parse(data.old_value);
        // console.log(dataparse);
        if(Array.isArray(dataparse)){
          let users:object[] = [];
          for(let d of dataparse){
            users.push(d.displayName);
          }
          from = users.join(', ');
        }
      }

      if(data.new_value){
        let dataparse:any = JSON.parse(data.new_value);
        if(Array.isArray(dataparse)){
          let users:object[] = [];
          for(let d of dataparse){
            users.push(d.displayName);
          }
          to = users.join(', ');
        }
      }

      msg += ` from <em>"<u>${from}</u>"</em> to <em>"<u>${to}</u>"</em>`;

    
    }else if(field.Type=='azure-user'){
      let from:any = '';
      let to:any = '';

      if(data.old_value){
        let dataparse:any = JSON.parse(data.old_value);
        if(dataparse)
          from = dataparse.displayName;
      }

      if(data.new_value){
        let dataparse:any = JSON.parse(data.new_value);
        if(dataparse)
          to = dataparse.displayName;
      }

      msg += ` from <em>"<u>${from}</u>"</em> to <em>"<u>${to}</u>"</em>`;
    }else if(field.Type=='switch'){
      let from:any = (String(data.old_value)==String(field.trueValue.value)) ? field.trueValue.label : field.falseValue.label;
      let to:any = (String(data.new_value)==String(field.trueValue.value)) ? field.trueValue.label : field.falseValue.label;
      msg += ` from <em>"<u>${from}</u>"</em> to <em>"<u>${to}</u>"</em>`;
    }else if(field.Type=='user'){
      msg += ` from <em>"<u>${this.commonservice.userName(Number(data.old_value))}</u>"</em> to <em>"<u>${this.commonservice.userName(Number(data.new_value))}</u>"</em>`;
    }else{
      msg += ` from <em>"<u>${data.old_value}</u>"</em> to <em>"<u>${data.new_value}</u>"</em>`;
    }


    msg += `  at <small>${this.momentTime(data.modified_date)}</small> </div> <br>`;
  }

  return msg;

  

}

momentDate(d:any=''){
  // console.log(d);
  let str:any = moment(d).calendar().split(" at")[0];
  // console.log(str); 
  return str; 
}

momentTime(d:any=''){
  // console.log(d);
  let str:any = moment(d).format('hh:mm A');
  // console.log(str); 
  return str; 
}

callTrail(){

  this.loader = true;
  this.audit_trail = [];
  let get_data: any = {
    parent_id: this.parent_id,
    module_code: this.module_code
  };
  if(this.parent_id){
    this.http.post<any>(this.url+'list', get_data).subscribe((r) => {
      if(r.data){
        // this.audit_trail = r.data;
        for(let a of r.data){
          let fdate = moment(a.modified_date).format('Y-MM-DD');
          let indx = this.globalservice.getDataIndex(this.audit_trail, 'date', fdate);
          if(indx==undefined){
            this.audit_trail.push({data:[], date:fdate });
            indx = 0;
          } 
          this.audit_trail[indx].data.push(a); 
        }
        // console.log(this.audit_trail);
        
      }
      
      this.loader = false;
    });      
  }
  
}



}
