

<div class="loader-cont" *ngIf="globalservice.loader">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    <br><p>{{globalservice.loader_msg}}</p>
</div>



<div class="i95-body" [ngClass]="{'dark_mode_on': darkModeStatus()}">
    
    <p-tieredMenu [popup]="true" #rightsidemenu [model]="right_sidemenu"></p-tieredMenu>

    

    
    <p-sidebar [(visible)]="show_message" position="right">
        <i95-app-chat
            *ngIf="show_message"
        ></i95-app-chat>
    </p-sidebar>
    
    
    
    
    
    <p-sidebar [(visible)]="show_side_left" [baseZIndex]="10000" >
    
    
        <!-- <div class="sidebar-container-bg">
    
        </div> -->
    
            <div class="upper_drawer pointer" (click)="changeRoute('')">
                <img [src]="globalservice.assetUrl('/assets/img/i95_white_logo.png')" class="main_logo">
                <!-- <div class="app-name">New Building & Innovation</div> -->
            </div>
    
            <div class="user_drawer">
                <img class="user_img" src="{{userservice.user_profile.userphotofile}}" [hidden]="!userservice.user_profile.userphotofile">
                <img class="user_img" [src]="globalservice.assetUrl('/assets/img/aman.png')" [hidden]="userservice.user_profile.userphotofile">
                <span class="user_info"
                [pTooltip]="userservice.user_profile.first_name+' '+userservice.user_profile.last_name"
                tooltipPosition="top"
                >
                    <span class="user_fname">{{userservice.user_profile.first_name}} {{userservice.user_profile.last_name}}</span>
                    <span class="user_email">{{userservice.user_profile.email}}</span>
                </span>
            </div>
            <div class="menu_drawer_list">
    
                <ng-container *ngFor="let item of menus; let i = index">
    
    
                    <ng-container *ngIf="!item.sub_modules" >
                        <div (click)="changeRoute(item.link)">
                            <img [src]="globalservice.assetUrl('/assets/img/ic/'+item.icon+'.svg')">
                            <span>{{item.name}}</span>
                        </div>
                    </ng-container>
    
    
                    <ng-container *ngIf="item.sub_modules" >
    
    
                        <div  (click)="openItem(item)">
                            <img [src]="globalservice.assetUrl('/assets/img/ic/'+item.icon+'.svg')">
                            <span>{{item.name}}</span>
                            <i class="pi" [ngClass]="{'pi-angle-down': item.selected, 'pi-angle-right': !item.selected}" style="float:right"></i>
                        </div>
                        <ng-container *ngIf="item.selected">
    
                            <div (click)="changeRoute(item.link+'/'+sitem.link)" *ngFor="let sitem of item.sub_modules; let ii = index"   class="pl-3">
                                <img [src]="globalservice.assetUrl('/assets/img/ic/'+sitem.icon+'.svg')">
                                <span>{{sitem.name}}</span>
                            </div>
                        </ng-container>
                    </ng-container>
    
    
    
    
                </ng-container>
    
    
    
    
            </div>
    
    
    
    </p-sidebar>
    
    
    <div class="i95-header">
        <div class="i95-title"
        [style]="globalservice.header_style" >
            {{globalservice.title}}
        </div>

        
    <div class="upper_icons">
    


        <button 
        [style]="globalservice.header_style"
        draggable="false" (click)="show_side_left = true" [hidden]="show_side_left">
             <i class="fa fa-bars"></i>
        </button>
    
    
      </div>
    
    
    
        <div class="i95-header-function-btn " 
        [style]="globalservice.header_style">


            <div class="i95-btn is_mobile_show"
            (click)="rightsidemenu.toggle($event)"> <i class="pi pi-ellipsis-v"></i> </div>


            <!-- <div class="i95-btn"> <i class="pi pi-user"></i> </div> -->
            <!-- <div class="i95-btn"> <i class="pi pi-cog"></i> </div> -->

            <div class="i95-btn is_not_mobile" style="width: 200px;" *ngIf="userNameShow()">
                <span>{{userservice.user_profile.first_name}} {{userservice.user_profile.last_name}}</span>
            </div>
            

            <div class="i95-btn is_not_mobile"
                [ngClass]="{'upper-btn-active': isSetHome()}"
                pTooltip="Set as Homepage"
                tooltipPosition="bottom"
                (click)="setAsHome()">
                <!-- <img src="./assets/img/chat.svg" alt=""> -->
                <i class="pi pi-home"></i>
            </div>

            <div class="i95-btn is_not_mobile" (click)="toggleDarkMode()">
                <i class="pi pi-moon" [hidden]="darkModeStatus()" pTooltip="Dark Mode On" tooltipPosition="bottom"></i>
                <i class="pi pi-sun" [hidden]="!darkModeStatus()" pTooltip="Dark Mode Off" tooltipPosition="bottom"></i>
            </div>
    
    
            <div class="i95-btn is_not_mobile"
                pTooltip="Messages"
                tooltipPosition="bottom"
                (click)="openMessages()">
                <!-- <img src="./assets/img/chat.svg" alt=""> -->
                <i class="pi pi-comments"></i>
            </div>
            <div class="i95-btn is_not_mobile" #notifbtn (click)="openNotification(notification, $event)" pTooltip="Notifications" tooltipPosition="bottom">
                <i class="pi pi-bell" pBadge value="{{notification_count}}" *ngIf="notification_count" severity="danger"></i>
                <i class="pi pi-bell" [hidden]="notification_count"></i>
             </div>
            <div class="i95-btn is_not_mobile"
    
            pTooltip="Logout"
            tooltipPosition="bottom"
            (click)="logoutConfirm()"> <i class="pi pi-sign-out"></i> </div>
        </div>
    </div>
    
    
    <p-overlayPanel #notification appendTo="body">
        <ng-template pTemplate>
    
            <div class="notification-header">
                <div class="notification-title">Notifications</div>
                <!-- <span class="notification-seemore">
                    <button pButton type="button" label="See All"  class=" p-button-text p-button-sm pull-right"></button>
                </span> -->
            </div>
            <div class="notification-container">
                
                <ng-container *ngIf="uploading && uploadActive;else doneUpload">
                    {{module}} - {{uploadMessage}}
                    <p-progressBar mode="indeterminate" [style]="{'margin-top':'15px'}"></p-progressBar>
                </ng-container>
                <ng-template #doneUpload >
                    <div *ngIf="uploadActive">
                        {{module}} - Excel data imported successfully.
                        <p-progressBar [style]="{'margin-top':'15px'}" [value]="100"></p-progressBar>
                    </div>
                </ng-template>
                
                
                <div class="notification-list-item" *ngFor="let n of notification_data">
                    <i class="notification-module-icon pi pi-apple"></i>
                    <div class="notification-details" [innerHTML]="n.details"></div>
                </div>
                <p-divider></p-divider>
    
                <!-- <div class="notification-list-item">
                    <i class="notification-module-icon pi pi-apple"></i>
                    <div class="notification-details"><b>System Admin</b> assigned a task to you in <b>Acion Log</b>.</div>
                </div>
    
                <p-divider></p-divider>
    
                <div class="notification-list-item">
                    <i class="notification-module-icon pi pi-clock"></i>
                    <div class="notification-details">You have pending <b>Timecard Overdue</b>.</div>
                </div>
    
                <p-divider></p-divider> -->
    
                <!-- <div class="notification-viewmore"> View More </div> -->
    
            </div>
        </ng-template>
    </p-overlayPanel>
    
    
    
    <p-confirmDialog [style]="{width: '300px'}" key="logoutdialog" [baseZIndex]="10000" id="confirmdialog" #lo>
    
    
    
        <p-footer>
            <button pButton type="button" (click)="lo.accept()" label="Yes" icon="pi pi-check" class=" p-button-text pull-right"></button>
            <button pButton type="button" (click)="lo.reject()" label="No" icon="pi pi-times" class=" p-button-text pull-right"></button>
        </p-footer>
    
    </p-confirmDialog>
    
    <div class="i95-main-bg"></div>
    
    <div class="main_content">
    
    
        <router-outlet></router-outlet>
    </div>

</div>



<p-toast 
styleClass="new-chat-notif"
position="bottom-right" key="chat-notif">


    <ng-template let-message pTemplate="message">
       
       <div class="new-message-container" (click)="openMessages(new_message_data.from)">
        <div class="new-chat-notif-profile-pic">
            <img [src]="commonservice.userProfilePicture(new_message_data.from)">
        </div>
            <div class="new-chat-notif-details">
                <div class="new-chat-notif-title">{{commonservice.userName(new_message_data.from)}}</div>
                {{new_message_data.message}}
            </div>
       </div>
    </ng-template>

</p-toast>


<p-dialog 
    [(visible)]="commonservice.show_file_viewer" 
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" 
    [style]="{width: 'auto',maxWidth: '90%'}" 
    [modal]=true 
    [showHeader]=false 
    [dismissableMask]=true 
    [resizable]="false"
    appendTo="body"
    styleClass="file_viewer"
    (onHide) = "commonservice.show_file_url = ''"
>
    
    <div class="img_loader" *ngIf="commonservice.img_loading">
        <i class="pi pi-spin pi-spinner"></i>  Loading...
    </div>

    <img 
        class="img-viewer" 
        [src]="commonservice.show_file_url" 
        [ngClass]="{'d-none': commonservice.img_loading}"
        alt="" 
        (load)="commonservice.onImageLoad()"
    >



</p-dialog>

