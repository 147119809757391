import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';  
import { UserService } from './user.service';

import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CustomCookieService } from './custome-cookie.service';





@Injectable()
export class ConfigService {
  public userid: any = 0;
  private sso_id: any = '';
  private secret: any = '';
  called: boolean = false;
  config_loaded: boolean = false;
  key: string = 'hello-hacker';
  public appConfig:any = {
    apiurl : '',
    debug: 'false',
    encryption: 'test'
  };
  public base_api_url:any = '';
  public userservice: any = {};
  constructor(
    private _http: HttpClient,
    private location: Location,
    private router: Router,
    private cookieService: CustomCookieService
    ) { 


    if(!this.called){
      this.appConfig = JSON.parse(JSON.stringify(environment));
      this.base_api_url = environment.apiurl;
      this.appConfig.apiurl = this.base_api_url+'api/';
    }

    this.appConfig.encryption = 'c886117b93eefe0';

  }
  clearListCookies(){
    var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++){   
            var spcook =  cookies[i].split("=");
            document.cookie = spcook[0] + "=;expires=Thu, 21 Sep 1979 00:00:01 UTC;";                                
        }
    }

  loadConfig(){
    
    var loader_element = (<HTMLInputElement>document.getElementById("initial-loader"));
    loader_element.classList.add("fade");
    setTimeout(()=>{             
      loader_element.classList.remove('fade')
      loader_element.classList.add("d-none");            
    }, 500);

    // this.clearListCookies();
    

    return new Promise<any>((resolve, reject)=>{

        this._http.get(this.appConfig.apiurl+'general/config')
        .subscribe((res:any) => {
          var ne: any = {
            datastring: ''
          };
           ne = res;
          // encrypt the config data
          var ret = JSON.parse(CryptoJS.AES.decrypt(ne.datastring, 'i95config').toString(CryptoJS.enc.Utf8));
    
            this.appConfig.debug = ret.debug;
            // this.appConfig.encryption = ret.encryption;
            this.secret = ret.encryption;

            localStorage.setItem('enc',this.encrypt(ret.encryption, true, this.key));
            this.cookieService.set('enc',this.encrypt(ret.encryption, true, this.key));
    
            // reset all storage data
            this.setUsrId();
            this.callCommonConfig();

            
            //var token = localStorage.getItem('token');
            var token = this.cookieService.get('token');
            if(token){
              this.callAlluserData((success:any, data:any) => {
                if(success){

                  resolve('done');
                }else{
                  
                  if(data.status==403){

                    resolve('done');
                  }
                  else if(data.status==401){

                    resolve('done');
                  }else{
                    // alert('Unable to retrieve your data.\n Please try again.');
                    setTimeout(() => {
                      window.location.reload();
                    },500);
                  }

                }
              });
            }else{

              // authenticate sso login
              this.ssoLogin(this.checkSSO()).then((res:any) =>{
                  resolve('done');
              });
            }
    
          }, err => {
            // console.log(err.error);
    
            
            this.appConfig.debug = true;
            this.appConfig.encryption = 'error';
            resolve('done');
            // setTimeout(()=>{                         
              alert('Unable to reach the server.\nPlease try again.');
            // }, 3000);
          });

    });
  
  }

  checkSSO(){
    
      let path:string = window.location.pathname;
      let sso_id: any = '';
      if(path.includes('/ssologin/')){
        let path_sliced: any = path.split('/');
        if(path_sliced.length>=3){
          sso_id = decodeURIComponent(path_sliced[2]);
          this.location.replaceState('');
          return CryptoJS.AES.decrypt(sso_id, 'token').toString(CryptoJS.enc.Utf8);
        }
      }
      return '';
  }
  ssoLogin(sso_id:any='') { 
    return new Promise<any>((resolve, reject)=>{

      if(sso_id==''){
        return resolve('');
      }

      this._http.post(this.getConfig().apiurl+'auth/ssologin', {token_id: sso_id}).subscribe(
        (res: any) => {
          if(res.success){
            localStorage.setItem('usrid', res.data.usrid);
            localStorage.setItem('token', res.data.access_token);
            localStorage.setItem('refresh_token', res.data.refresh_token);

            this.cookieService.set('usrid',res?.data?.usrid);
            this.cookieService.set('token',res?.data?.access_token);
            this.cookieService.set('refresh_token',res?.data?.refresh_token);

            this.setUsrId();
            this.callAlluserData((success:any, data:any) => {
                if(success){      
                  return resolve('success');
                }else{
                  return resolve('');
                }
              });
          }else{
            
            return resolve('');
          }

        },
        err => {
          return resolve('');
        }
      );
    });
  }

  defaultCallback(t1:any,t2:any){
    // alert();
    // console.log(t2);
    // return {t1, t2};
   }
  resetTempCommons(remains:any = []){
    var localdata:any  = Object.keys(localStorage);
     for(let d of localdata){
        if(d.includes('temp_')){
          // localStorage.removeItem(d);
          if(remains.length>0){
            let temp_d:any =  d.replace('temp_','');
            if(!remains.includes(temp_d)){       
              localStorage.removeItem(d);
            }
          }else{
            
            localStorage.removeItem(d);
          }

        }
      }
  }

  callCommonConfig = () => {
      
    this._http.get(this.appConfig.apiurl+'general/config/commons').subscribe((e:any)=>{
      let local_common_config:any = localStorage.getItem('i95commonconfig');
      localStorage.setItem('i95commonconfig', e.datastring);
      var ret = JSON.parse(CryptoJS.AES.decrypt(e.datastring, 'i95commonconfig').toString(CryptoJS.enc.Utf8));
      let remain:any = [];
      if(local_common_config!=null){
        var ret2 = JSON.parse(CryptoJS.AES.decrypt(local_common_config, 'i95commonconfig').toString(CryptoJS.enc.Utf8));
        for(let r of ret2){
          let rdata:any = this.getData(ret,'setting_name', r.setting_name);
          if(rdata){
            if(rdata.id==r.id){
              remain.push(rdata.setting_name);
            }
          }
        }
        
        this.resetTempCommons(remain);
      }else{
        this.resetTempCommons();
      }


    }, error => {
      this.resetTempCommons();

    });
  } 

  callAlluserData = (callBack:any = this.defaultCallback) => {
    this._http.get(this.appConfig.apiurl+'user/alluserdata').subscribe((e:any)=>{
      let alldata:any = e.data;

      // login profile
      var loginprofile = this.encrypt(JSON.stringify(alldata.loginprofile));
      localStorage.setItem('user_profile',loginprofile);  
      // this.cookieService.set('user_profile',loginprofile);

      // module_access profile
      var module_access = this.encrypt(JSON.stringify(alldata.module_access));
      localStorage.setItem('module_access',module_access);  
      // this.cookieService.set('module_access',module_access);
      // module_access account type 
      var module_acccount_type = this.encrypt(JSON.stringify(alldata.module_account_type));
      localStorage.setItem('module_account_type',module_acccount_type);  
      // this.cookieService.set('module_account_type',module_acccount_type);

      // module_access profile
      var ship = this.encrypt(JSON.stringify(alldata.ship));
      localStorage.setItem('ship_access',ship);  
      // this.cookieService.set('ship_access',ship);
      
      // all user data action
      let user_data = this.encrypt(JSON.stringify(alldata.user_data));
      localStorage.setItem('temp_user_data', user_data);
      // this.cookieService.set('temp_user_data',user_data);
       // user access data
       var user_access_data = this.encrypt(JSON.stringify(alldata.user_access_data));
       localStorage.setItem('user_access_data',user_access_data);  
      //  this.cookieService.set('user_access_data',user_access_data);

      callBack(true,true);
    }, error=>{
      callBack(false,error);
    });
  };

  setUsrId(){
    //var enc_id = localStorage.getItem('usrid');
    var enc_id = this.cookieService.get('usrid');
    if(enc_id && this.appConfig.encryption){
      this.userid = JSON.parse(CryptoJS.AES.decrypt(enc_id, this.appConfig.encryption).toString(CryptoJS.enc.Utf8));
    }
  }
  getData(Arr: any = [],key: any = '',value: any = '') {
    var ret;
    if(!Arr) return undefined;
  
    if(Arr.length > 0){
      Arr.forEach(function(item:any){
        if(item[key] === value){
            ret = item;
        }
      });
    }
  
  
    return ret;
  };

  encrypt(value : string, tostring: boolean = false, secret:any = '') : string{
    var secret_key = this.getConfig().encryption;

    // console.log('encrypt enc_key', this.appConfig, value);

    if(secret!=''){
      secret_key = String(secret);
    }
    if(tostring){
      value = JSON.stringify(value);
    }


    
    return CryptoJS.AES.encrypt(value, secret_key.trim()).toString();
  }
  
  decrypt(textToDecrypt : string, fromstring: boolean = false, secret:any = ''){
    
    // console.log('decrypt enc_key', this.appConfig, textToDecrypt);
    var secret_key = this.getConfig().encryption;
    if(secret!=''){
      secret_key = secret;
    }
    
    
    
    // console.log('secret', textToDecrypt, secret_key.trim());

    var res = CryptoJS.AES.decrypt(textToDecrypt, secret_key.trim()).toString(CryptoJS.enc.Utf8);
    if(fromstring){
      return JSON.parse(res);
    }else{
      return res;
    }
  }

  sha(data: string) {
    if(typeof(data)=='object'){
        data = JSON.stringify(data);
    }
    return CryptoJS.SHA256(data);
}




  getUserOnline(){

    let env:any = environment;
    const socket_server = (env?.socket_server) ? env?.socket_server: '';

    return this._http.post(socket_server+'/get_online_new', {});
  }

  getConfig(){
    // console.log('getConfig', this.appConfig);
    return this.appConfig;
  }
}
