import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'i95-user-dropdown-wizard',
    templateUrl: './user-dropdown-wizard.component.html',
    styleUrls: ['./user-dropdown-wizard.component.scss'],
    standalone: true,
    imports: [NgIf, DropdownModule, FormsModule, AvatarModule, SharedModule, InputTextModule, NgTemplateOutlet, TooltipModule, MultiSelectModule]
})
export class UserDropdownWizardComponent implements OnInit {
  
  filterTextChanged: Subject<string> = new Subject<string>();
  @Input() userControlName: any;
  @Input() appendTo: any = '';
  @Input() Type: any = 'dropdown';
  @Input() userOnChange: any;
  @Input() disabled: boolean = false;
  @Input() showClear: boolean = false;
  @Input() allowed_users: any = false;
  
  @Input() get userModel(): any { return this.user_model; }
  @Output() userModelChange = new EventEmitter<any>();

  @Input() styleClass: string = '';
  @Input() placeholder: string = 'Select User';
  @Input() required: boolean = false;
  @Input() user_data: any;
  user_model: any;
  user_option: any = [];
  user_fullname: any = '';


  constructor(
    public commonservice: CommonService,
  ) { 
    this.commonservice.loadCommon('users',(err: any, result: any) => {
      // console.log(this.commonservice.data.users);
    });
    this.setUserOption();


  }

  changeValue(e:any){
    this.userModelChange.emit(this.user_model);
    // alert(e.value);
    if(this.user_model!=e.value && e.value!=null && this.user_model!=null){
      this.userOnChange();
    }
  }

  set userModel(usermodel: any) {
    this.user_model = usermodel;
    // this.setUserOption();


}
userAssignedList(){
  var r = '';
  
  if(this.Type=='multiple' && Array.isArray(this.user_model)){

  //   console.log(this.user_model);
    for(let u of this.user_model){
      r += this.commonservice.userName(u)+'\n';
    }


  }


  return r;
}



  ngOnInit(): void {
    
    // alert(this.styleClass);
    if(this.Type=='multiple' && !Array.isArray(this.user_model)){
      this.user_model = [];
    }

    


  }



  user_no_result: boolean = false;
  setUserOption(val: any = ''){

    this.user_option = [];
      var lim: number = 100;
      if(!val){
          this.user_option = this.commonservice.data.users.slice(0, lim);
      }else{
        this.user_option = [];

        var c = 0;
        for(let u of this.commonservice.data.users){
          if(c>=lim){
            break;
          }
          if((u.first_name+' '+u.last_name).toLowerCase().includes(val.toLowerCase())){
            this.user_option.push(u);
            c++;
          }
        } 
      }

      if(this.user_option.length<=0){
        this.user_no_result = true;
      }
  }


  userList(){
    // console.log('this.allowed_users', this.allowed_users);
    if(this.allowed_users){
      let u:any = [];
      for(let usr of this.commonservice.data.users){
        if(this.allowed_users.includes(usr.id)){
          u.push(usr);
        }
      }

      return u;

    }else{
      return this.commonservice.data.users;
    }
  }


  onFilterTextChanged(filterText: string) {
    this.user_no_result = false;
    if (this.filterTextChanged.observers.length === 0) { 
      this.user_option = [];
      this.filterTextChanged
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(filterQuery => {
          this.setUserOption(filterQuery);
        });
    }
    this.filterTextChanged.next(filterText);
  }


}
