
<ng-template #myTemplate> World! </ng-template>

<ng-template #formtemplate let-fieldset>


    <ng-container *ngFor="let field of fieldset.fieldset" >
        <!-- [ngClass]="{'has-error': service.show_errors && service.error_data[field.Id]}" -->
        <div class="field {{field.Col}} " 
        pTooltip="{{toolTipValue(field)}}" tooltipPosition="top"
        *ngIf="!field.hideForm || allowShowField(field.Id)" [ngClass]="{'has-error': (show_error && error_data[field.Id]) || checkIfHighlight(field.Id) }" [hidden]="field.Type=='image' || field.Type=='images' || field.Type=='hidden' || field.hidden_form">
            <div [ngSwitch]="field.Type" >

                
                <h4 class="mb-0 form-label">
                    <ng-container  *ngIf="field.Type!='button'">{{field.Label}} </ng-container> &nbsp;
                <span *ngIf = "field.Required" class="asterisk_required">*</span></h4>
    
                    
            <div  *ngSwitchCase="'button'" class="custom-button">
                <button 
                    class="inputfield w-full p-button-sm {{field.innerClass}} " 
                    pButton 
                    type="button" 
                    [disabled]="disabled"
                    (click)="field.command()"
                    [icon]="(field.Icon) ? field.Icon : ''"
                    [label]="field.Label" >
                </button>
                
                <span class="count-badge" >{{field.countBadge()}}</span>
            </div>


                <input *ngSwitchCase="'input'" type="text"
                        pInputText
                        (blur)="fieldChanges(field, new_data[field.Id])"
                        [disabled]="!field.enableCellEdit || disabled"
                        class="inputfield w-full {{field.innerClass }}"
                        [(ngModel)]="new_data[field.Id]" 
                        [required]="field.Required"
                       
                    >
    

                <input *ngSwitchCase="'number'" 
                    type="number"
                    pInputText
                    (blur)="fieldChanges(field, new_data[field.Id])"
                    [disabled]="!field.enableCellEdit || disabled"
                    class="inputfield w-full {{field.innerClass }}"
                    [(ngModel)]="new_data[field.Id]" 
                    [required]="field.Required"
                       
                    >
    
                <p-calendar
                    *ngSwitchCase="'date'"
                    #calendarview
                    autoWidth="false"
                    appendTo="body"
                    [selectionMode]="(field.selection) ? field.selection : 'single'"
                    [(ngModel)]="new_data[field.Id]"
                    [disabled]="!field.enableCellEdit || disabled"
                    [readonlyInput]="true"
                    [required]="field.Required"
                    (onSelect)="fieldChanges(field, new_data[field.Id])"
                    (onClearClick)="fieldChanges(field, [])"
                    [showButtonBar]="true"
                    [style]="{'width':'100%'}"
                    [maxDate]="(field.maxDate) ? field.maxDate : null"
                    [minDate]="(field.minDate) ? field.minDate : null"></p-calendar>
    
    
                <textarea pInputTextarea *ngSwitchCase="'textarea'" 
                    (blur)="fieldChanges(field, new_data[field.Id])"
                    [(ngModel)]="new_data[field.Id]" rows="4"  [disabled]="!field.enableCellEdit || disabled"
                    class="inputfield w-full"></textarea>
    
    
                <p-dropdown *ngSwitchCase="'select'" 
                    [options]="getDropdown(field.Value)" 
                    [virtualScroll]="true"
                    [itemSize]="30" placeholder="{{field.Label}}" [filter]="true" filterBy="name"
                    [appendTo]="append_to"  optionLabel="name" 
                    [(ngModel)]="new_data[field.Id]"
                    
                    [disabled]="!field.enableCellEdit || disabled"
                    [required]="field.Required"
                    autoWidth="false" optionValue="code" [style]="{'width':'100%'}"
                    [showClear]="field.showClear"
                    >
                </p-dropdown>


                <p-multiSelect 
                    *ngSwitchCase="'select-multiple'"
                    [options]="getOptions(field)" [virtualScroll]="true"
                    [(ngModel)]="new_data[field.Id]" 
                    [appendTo]="append_to"
                    [filter]="true"
                    (onChange)="fieldChanges(field, new_data[field.Id])"
                    [required]="field.Required"
                    [disabled]="!field.enableCellEdit || disabled"
                    [showClear]="field.showClear"
                    [style]="{'width':'100%'}"
                    [optionLabel]="(field.OptionLabel) ? field.OptionLabel : '' "
                    [optionValue]="(field.OptionValue) ? field.OptionValue : '' "
                >
                </p-multiSelect>
    


                    



                <p-dropdown *ngSwitchCase="'select2'"
                    [options]="getOptions(field)" [virtualScroll]="true"
                    [itemSize]="30" placeholder="{{field.Label}}" [filter]="true" filterBy="{{field.filterBy ? field.filterBy :'name'}}"
                    [appendTo]="append_to"
                    [showClear]="true"
                    optionDisabled="disabled"
                    [optionLabel]="(field.OptionLabel) ? field.OptionLabel : '' "
                    [optionValue]="(field.OptionValue) ? field.OptionValue : '' "
                    [(ngModel)]="new_data[field.Id]"
                    (onChange)="fieldChanges(field, new_data[field.Id])"
                    [required]="field.Required"
                    autoWidth="false"
                    [style]="{'width':'100%'}"
                    [disabled]="!field.enableCellEdit || disabled"
                    >
                </p-dropdown>    


                <i95-user-dropdown-wizard *ngSwitchCase="'user'"
                        [userControlName]="field.Id"
                        [showClear]="true"
                        [allowed_users]="field.inGeneralFilter?.options"
                        [userModel]="new_data[field.Id]"
                        [user_data]="new_data[field.Id]"
                        [placeholder]="field.Label"
                        [allowed_users]="(reference[field.Id]) ? reference[field.Id] : false"
                        Type="dropdown"
                        [disabled]="!field.enableCellEdit || disabled"
                        (userModelChange)="fieldChanges(field, $event); new_data[field.Id]=$event"
                        appendTo="body">
                </i95-user-dropdown-wizard>

                <p-dropdown *ngSwitchCase="'normal-select'"
                    [options]="field.Options" [virtualScroll]="true"
                    [itemSize]="30" placeholder="{{field.Label}}" [filter]="true" filterBy="value"
                    [appendTo]="append_to"
                    optionDisabled="disabled"
                    [optionLabel]="'value'"
                    [optionValue]="'value'"
                    [(ngModel)]="new_data[field.Id]"
                    (onChange)="fieldChanges(field, new_data[field.Id])"
                    [required]="field.Required"
                    autoWidth="false"

                    [showClear]="true"
                    [style]="{'width':'100%'}"
                    [disabled]="!field.enableCellEdit || disabled"
                    >
                </p-dropdown> 
    
                <p-inputSwitch
                    *ngSwitchCase="'switch'"
                    styleClass=""
                    [disabled]="!field.enableCellEdit || disabled"
                    class="pl-0 pr-2"
                    [(ngModel)]="new_data[field.Id]"
                    [trueValue]="field.trueValue.value"
                    [falseValue]="field.falseValue.value"
                    (onChange)="fieldChanges(field, new_data[field.Id])">
                </p-inputSwitch>
    
                <p-toggleButton onLabel="Yes" 
                [disabled]="!field.enableCellEdit || disabled" *ngSwitchCase="'switch2'" offLabel="No" offIcon="pi pi-times" iconPos="left" onIcon="pi pi-check" [(ngModel)]="new_data[field.Id]"  [style]="{'width': '100%'}"></p-toggleButton>
    
                <azure-input-search
                    *ngSwitchCase="'azure-user'"
                    styleClass="form-control col-sm-12"
                    (selectAzureUserEvent)="new_data[field.Id] = $event; fieldChanges(field, new_data[field.Id])"
                    [model]="new_data[field.Id]"
                    [required]="field.Required"
                    [appendTo]="field.appendTo"
                    [disabled]="!field.enableCellEdit || disabled"
                >
                </azure-input-search>
    
                <azure-multiple-selection
                    *ngSwitchCase="'azure-user-multiple'"
                    styleClass="form-control col-sm-12"
                    (selectAzureUserEvent)="new_data[field.Id] = $event; fieldChanges(field, new_data[field.Id])"
                    [model]="new_data[field.Id]"
                    [required]="field.Required"
                    [appendTo]="field.appendTo"
                    [disabled]="!field.enableCellEdit || disabled"

                    >
                </azure-multiple-selection>
    
                <small *ngIf="show_error && error_data[field.Id]">{{error_data[field.Id]}}</small>
            </div>
        </div>
    </ng-container>

</ng-template>


<div class="loadingform" *ngIf="loading">
    <i class="fa fa-spin fa-spinner mr-1"></i> Loading...
</div>


<p-confirmDialog [style]="{width: '400px'}" key="confirmform" [baseZIndex]="10000" id="confirmform" #confirmform>
    <p-footer>
        <button pButton type="button" (click)="confirmform.accept()" label="Yes" icon="pi pi-check"
            class=" p-button-text pull-right"></button>
        <button pButton type="button" (click)="confirmform.reject()" label="No" icon="pi pi-times"
            class=" p-button-text pull-right"></button>
    </p-footer>
</p-confirmDialog>

<div *ngIf="!loading">
    <div class="grid mt-0 form-content row auto-scroll" style="overflow-y:scroll;position:relative;{{divSyle()}}">
    

        <ng-container 
            *ngIf="!groups">
            <ng-container 
                *ngTemplateOutlet="formtemplate; context: {$implicit: {fieldset: fields}}" >                                    
            </ng-container>
        </ng-container>




    <ng-container *ngIf="groups" >
        <ng-container *ngFor="let grp of groups" >

            <div class="{{grp.class}}">
                <p-fieldset [legend]="grp.label" styleClass="common_fieldset_class {{(fieldClass(grp))}}" [toggleable]="grp?.toggleable">
                    <div class="grid mt-0 form-content row auto-scroll" style="overflow-y:scroll;position:relative;{{divSyle()}}">
                        <ng-container
                            *ngTemplateOutlet="formtemplate; context: {$implicit: {fieldset: groupField(grp)}}" >
                        </ng-container>
                    </div>
                </p-fieldset>
            </div>
        </ng-container>

    </ng-container>

    
    </div>
    
    <div class="p-3 mt-2" [hidden]="nobtn">
        <button pButton type="button" (click)="saveNewData()" label="Save" icon="pi pi-save"
        class=" pull-right p-button-sm"></button>
        <button pButton type="button" (click)="restData()" label="Reset" icon="pi pi-refresh"
        class="p-button-secondary pull-right mr-2 p-button-sm"></button>
    </div>
</div>


  <!-- <i95-user-dropdown-wizard
                    *ngSwitchCase="'user'"
                    [userControlName]="field.Id"
                    [userModel]="formGroup.value[field.Id]"
                    (userModelChange)="formGroup.controls[field.Id].setValue($event)"
                    [required]="field.Required"
                    styleClass="form-control col-sm-12"
                    Type="dropdown"
                    [showClear]="field.showClear"
                    [userOnChange]='userOnChange'
                    [appendTo]="append_to"
                ></i95-user-dropdown-wizard> -->
    
                <!-- <i95-user-dropdown-wizard
                    *ngSwitchCase="'user-multiple'"
                    [userControlName]="field.Id"
                    [userModel]="formGroup.value[field.Id]"
                    (userModelChange)="formGroup.controls[field.Id].setValue($event)"
                    [userOnChange]='userOnChange'
                    [required]="field.Required"
                    styleClass="form-control col-sm-12"
                    Type="multiple"
                    [appendTo]="append_to"
                ></i95-user-dropdown-wizard> -->
                
                <!-- <p-multiSelect [options]="getDropdown(field.Value)" *ngSwitchCase="'multiselect'"
    
                    placeholder="{{field.Label}}" [formControlName]="field.Id" optionLabel="name" optionValue="code"
                    [virtualScroll]="true" [itemSize]="30" autoWidth="false"
                    [appendTo]="append_to"  display="chip" [required]="field.Required"
                    [style]="{'width':'100%'}">
                </p-multiSelect> -->
    
                <!-- <p-multiSelect *ngSwitchCase="'multiselect2'" [options]="getOptions(field)" [virtualScroll]="true" [itemSize]="30"
                    placeholder="{{field.Label}}" [filter]="true" filterBy="name" [appendTo]="append_to"
                    [optionLabel]="(field.OptionLabel) ? field.OptionLabel : '' "
                    [optionValue]="(field.OptionValue) ? field.OptionValue : '' " [formControlName]="field.Id"
                    [required]="field.Required" autoWidth="false"  [style]="{'width':'100%'}" display="chip">
                </p-multiSelect> -->
    
                <!-- <p-multiSelect [options]="getDropdown(field.Value)" *ngSwitchCase="'multiselect2'" placeholder="{{field.Label}}"
                    [formControlName]="field.Id" optionLabel="name" optionValue="code" [virtualScroll]="true" [itemSize]="30"
                    autoWidth="false" [appendTo]="append_to" display="chip" [required]="field.Required" [style]="{'width':'100%'}">
                </p-multiSelect> -->