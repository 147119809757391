import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

import {MessageService} from 'primeng/api';
import {Title} from "@angular/platform-browser";
// SocketService




import * as CryptoJS from 'crypto-js';  
import { ConfigService } from './config.service';
import { CustomCookieService } from './custome-cookie.service';





@Injectable()
export class GlobalService {
  secretKey: String = 'eb0a61fc5141e89e6935bc98fa57e1ec';
  title: any = '';
  header_style: any = '';
  loader: boolean = false;
  loader_msg: string = '';
  global_class: string = 'global_class';

  system_modules: any = [
    {
      icon: 'ic_user_management_regular',
      icon_filled: 'ic_user_management_filled',
      name: 'User Setup',
      shortName:'User',
      link: 'user-setup',
      pi_icon: 'users'
    },
    {
      icon: 'ic_project_setup_regular',
      icon_filled: 'ic_project_setup_filled',
      name: 'Project Setup',
      shortName:'Project',
      link: 'project-setup',
      pi_icon: 'chart-bar'
    },
    {
      icon: 'ic_user_management_regular',
      icon_filled: 'ic_user_management_filled',
      name: 'Ship Management',
      shortName:'Ship',
      link: 'ship-setup',
      pi_icon: 'shield'
    },
    {
      icon: 'ic_user_management_regular',
      icon_filled: 'ic_user_management_filled',
      name: 'Company Management',
      shortName:'Company',
      link: 'company-setup',
      pi_icon: 'id-card'
    },
    // {
    //   icon: 'ic_global_references_regular',
    //   icon_filled: 'ic_global_references_filled',
    //   name: 'Global Reference',
    //   shortName:'Reference',
    //   link: 'reference',
    //   pi_icon: 'list'
    // },
    {
      icon: 'ic_global_references_regular',
      icon_filled: 'ic_global_references_filled',
      name: 'Help Desk Request',
      shortName:'Help Desk Request',
      link: 'helpdesk-request',
      pi_icon: 'list'
    },

  ];

  constructor(
    private router: Router,
    private location: Location,
    private http: HttpClient,
    private config: ConfigService,
    private titleService:Title,
    public messageService: MessageService,
    private cookieService: CustomCookieService
    ) { 

    }

  showLoading(msg:string='Loading Please wait...'){
      this.loader = !this.loader;
      this.loader_msg = msg
  }


  showSuccess(msg:any = '', title: any="Success") {
      this.messageService.add({key: 'tc', severity:'success', summary: title, detail: msg});
  }

  showInfo(msg:any = '', title: any="Info") {
    this.messageService.add({key: 'tc', severity:'info', summary: title, detail: msg});
}

showWarn(msg:any = '', title: any="Warning") {
    this.messageService.add({key: 'tc', severity:'warn', summary: title, detail: msg});
}

showError(msg:any = '', title: any="Error") {
    this.messageService.add({key: 'tc', severity:'error', summary: title, detail: msg});
}

showchatNotif(data:any = {}){
  this.messageService.clear();
  this.messageService.add({key: 'chat-notif', severity:'info', summary: 'New Message', life:3000});
}

  setTitle(t: string = ''){
    this.title = t;
    this.titleService.setTitle(t);
  }
  
  Location(url: String = ''){
    this.location.replaceState(String(url));
  }
  Route(url: String = ''){
    this.router.navigate([url])

  }
  refreshing_token:boolean = false;
  gettoken(){
    if(!this.refreshing_token){
      var token = this.cookieService.get('refresh_token');
      this.refreshing_token = true;
      this.http.post<any>(this.config.getConfig().apiurl+'auth/token',{ token: token}).subscribe(e => {
            if(e.success){
                localStorage.setItem('token', e.data.access_token);
                localStorage.setItem('refresh_token', e.data.refresh_token);

                this.cookieService.set('token',e?.data?.access_token);
                this.cookieService.set('refresh_token',e?.data?.refresh_token);

                this.showInfo('Your token has expired. This page will reload.')
                setTimeout(() => {
                    window.location.reload();
                  }, 2000);
            }
        },(error) => {        
            localStorage.clear();
            this.cookieService.logout();
            this.router.navigateByUrl('/login');
            this.refreshing_token = false;
        });
    }
  
  }


  assetUrl(link:string='') {
    return environment.assets_url + link
}


  test(){
    alert();
  }

  dcArray(arr: any = []){
    let string_col = JSON.stringify(arr);
    return JSON.parse(string_col);
}
  

// sortA(multidimentionalarray, ['column name'])
  sortD(a:any = [], by: any = []) {
    return a.map(function (el: any, i:any) {
        return {
            index: i,
            value: by.reduce(function (obj:any, property:any) { return obj[property]; }, el)
        };
    }).sort(function (a:any, b:any) {
        return   b.value - a.value;
    }).map(function (el:any) {
        return a[el.index];
    });
}

// sortD(multidimentionalarray, ['column name'])
  sortA(a:any = [], by: any = []) {
    return a.map(function (el: any, i:any) {
        return {
            index: i,
            value: by.reduce(function (obj:any, property:any) { return obj[property]; }, el)
        };
    }).sort(function (a:any, b:any) {
        return  a.value - b.value;
    }).map(function (el:any) {
        return a[el.index];
    });
}

// getData(multidimentionalarray, columnname, value);
getData(Arr: any = [],key: any = '',value: any = '') {
  var ret;
  if(!Arr) return undefined;

  if(Arr.length > 0){
    Arr.forEach(function(item:any){
      if(item[key] === value){
          ret = item;
      }
    });
  }

  return ret;
};

setSession(k:string='', data: any=''){
  sessionStorage.setItem(k, data);
}
getSession(k:string='', data: any=''){
  return sessionStorage.getItem(k);
}
getTempSession(k:string='', data: any=''){
  let v = sessionStorage.getItem(k);
  sessionStorage.removeItem(k);
  return v;
}

getItemData(Arr: any = [],key: any = '',value: any = '', field:any=''){
  let data:any = this.getData(Arr, key, value);
  if(data){
    return data[field];
  }else{
    return '';
  }
}


getShowData(Arr: any = [],key: any = '',value: any = '', field: any = '') {
  var ret;
  Arr.forEach(function(item:any){
      if(item[key] === value){
          ret = item;
      }
  });
  var val:any = '';
  if(ret){
    val = ret[field];
  }

  return val;
};

// removeData(multidimentionalarray, columnname, value); 
// remove data from the json object
removeData(Arr: any = [],key: any = '',value: any = '') {
  var data_index = this.getDataIndex(Arr, key, value);
  if(data_index!=undefined){
    Arr.splice(data_index, 1);
  }
};

  removeObjectData(arr: any = [], data: any = {}) {
    for (var i = 0; i < arr.length; i++) {
      if (JSON.stringify(arr[i]) === JSON.stringify(data)) {
        arr.splice(i, 1);
      }
    }
  }

  // removeData(multidimentionalarray, columnname, value); 
  // remove data from the json object
  updateData(Arr: any = [], key: any = '', value: any = '',newData={}) {
    var data_index = this.getDataIndex(Arr, key, value);
    if (data_index != undefined) {
      Arr[data_index] = newData;
    }
  };


// addUniqueData(multidimentionalarray, columnname, data_to_add); 
// this will add a unique data depends on the value of the key
addUniqueData(Arr: any = [],key: any = '',data:any = {}){
  var data_index = this.getDataIndex(Arr, key, data[key]);
  if(data_index==undefined){
    Arr.push(data);
  }
};


getMultiData(Arr: any = [],key: any = '',value: any = '') {
  var ret: any = [];
  Arr.forEach(function(item:any){
      if(item[key] === value){
          ret.push(item);
      }
  });

  return ret;
};



getDataIndex = function (Arr: any = [],key: any = '',value: any = '') {
  var ret: any ;
  Arr.forEach(function(item:any, i:any){
      if(item[key] === value){
          ret = i;
      }
  });

  return ret;
};

getlikeMultiData(Arr: any = [], key: any = '',value: any = '', limit=1000) {
  var ret: any = [];
  var n = 0;
  Arr.forEach(function(item:any){
      // console.log(item[key].includes(value));

      if(item[key].toLowerCase().includes(value.toLowerCase()) && limit>=n){
          n++;
          ret.push(item);         
      }

  });

  return ret;
};


activeModule(link: any = ''){
  // console.log(this.router.url, link);
  return (this.router.url==link);;
}

logout() {
  // localStorage.removeItem('token');
  // this.cookieService.clear();
  localStorage.clear();
  // this.Route('/login');
  
  
}

isInArray(value:any, array:any) {
  return array.indexOf(value) > -1;
}

isEmptyString(value:any){
  if(value != null && value != '' && value != undefined && value != 'undefined' && value != 'Invalid date' && value != '0000-00-00'){
    return false;
  }

  return true;

}

isJsonString(str:string) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

getTextColor (bgColor:any , lightColor = '#FFFFFF', darkColor = '#000000') {
  const getLuminance = function (hexColor:any) {
    var color = (hexColor.charAt(0) === '#') ? hexColor.substring(1, 7) : hexColor
    var r = parseInt(color.substring(0, 2), 16) // hexToR
    var g = parseInt(color.substring(2, 4), 16) // hexToG
    var b = parseInt(color.substring(4, 6), 16) // hexToB
    var uicolors = [r / 255, g / 255, b / 255]
    var c = uicolors.map(col => col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4)

 

    return (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  }

  var L = getLuminance(bgColor)
  var L1 = getLuminance(lightColor)
  var L2 = getLuminance(darkColor)
  return (L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05) ? darkColor : lightColor;
}

getAvg(data:any){
  if(data.match("-")){
    // return 'heloo';
    let str:any = data.split('-');
    let int1 = 0;
    let int2 = 0;
    if(str[0]){
      int1 = str[0].replace(/\s+/g, '');
    }
    if(str[1]){
      int2 = str[1].replace(/\s+/g, '');
    }
    let avg:any = Math.abs((int1-int2)/2)
    let whole = (int1>int2) ? int2 : int1
    return Number(whole)+Number(avg)

  }else{
    return Number(data);
  }
}

arrayChecker(array:any){
  if(typeof array === "object" && !Array.isArray(array) && array !== null){
    array = [array];
  }else{
    if(Array.isArray(array)){
      array = array;
    }else{
      array = [];
    }
  }

  return array;
}

numbersOnly(num:any){

 return num.replace(/\D+/g, '');

}

}
