import { Component, OnInit, ViewChild, ElementRef,QueryList,ViewChildren, Renderer2, Input, Output, ViewContainerRef, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { ContextMenuModule } from 'primeng/contextmenu';
import { CommonService } from 'src/app/services/common.service';
import { ConfigService } from 'src/app/services/config.service';
import * as moment from 'moment-timezone';
import { GlobalService } from 'src/app/services/global.service';
import { RippleModule } from 'primeng/ripple';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { AzureMultipleSelectionComponent } from '../../../../feature/azure-multiple-selection/azure-multiple-selection.component';
import { AzureInputSearchComponent } from '../../../../feature/azure-input-search/azure-input-search.component';
import { UserDropdownWizardComponent } from '../../user-dropdown-wizard/user-dropdown-wizard.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass, NgTemplateOutlet,DOCUMENT } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { ImageModule } from 'primeng/image';




@Component({
    selector: 'i95-tables',
    templateUrl: './customtable.component.html',
    styleUrls: ['./customtable.component.scss'],
    standalone: true,
    imports: [ContextMenuModule, DialogModule,ScrollingModule, OverlayPanelModule, NgFor, SharedModule, FormsModule, ButtonModule, TooltipModule, NgIf, NgSwitch, NgSwitchCase, InputSwitchModule, ColorPickerModule, NgSwitchDefault, UserDropdownWizardComponent, AzureInputSearchComponent, AzureMultipleSelectionComponent, MultiSelectModule, DropdownModule, CalendarModule, InputNumberModule, InputTextareaModule, InputTextModule, NgClass, TableModule, CheckboxModule, NgTemplateOutlet, RippleModule, ImageModule]
})
export class CustomtableComponent implements OnInit {
 
  @Input() appScope: any = {};
  @Input() groupBy: any = false;
  @Input() selection: boolean = false;
  @Input() showAction: boolean = true;
  @Input() filterValue: any = {};
  @Input() allowEdit: boolean = false;
  @Input() allowFilter: boolean = false;
  @Input() hidePagination: boolean = false;
  @Input() get show_table(): any { return this.show; }
  @Input() type: any;
  @Input() styleClass: any='';
  @Input() seemore_fields: any;
  @Input() fields: any;
  @Input() value: any = [];
  @Input() reference:any = {};
  @Input() loading: any= false;
  @Input() allow_filter: any= false;
  @Input() table_height: any = 300;
  @Input() reduce_height: any = 0;
  @Input() totalRecords: any = 300;
  @Input() virtual_row_height: any = 40;
  @Input() resizeColumn:boolean = true;
  @Input() default_filter:any;

  @Input() disableContext: boolean = false
  @Input() no_data_msg: any = 'No records found';

  
  @Input() general_filter: any = [];
  @Input() general_search: any = [];
  sortfield:string='id';
  sortorder: any = 0;
  @Output() onLazyLoad = new EventEmitter<any>();
  @Output() openItemMenu = new EventEmitter<any>();
  @Output() inLineChange = new EventEmitter<any>();
  @Output() itemChecked = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<any>();

  @Output() triggerDoubleClick = new EventEmitter<any>();

  @Input() triggerFunction = new EventEmitter<any>();

  @Input() rowsPerPageOptions = [10,50, 100, 250, 500];
  @Input() rows = 50;
  @Input() disable_virtual_scroll: any = false;


  temp_hide_fields: boolean = false;
  selected_item: any = [];
  users: any = [];
  selected_column: any = {};

  @ViewChild('i95table', { static: false })
  i95table!: ElementRef;


  @ViewChildren('cellitem') cellitem!: QueryList<ElementRef>;

  @ViewChild('comments') comments!: ElementRef;
  @ViewChild('commentpanel') commentpanel!: ElementRef;
  @ViewChild('scapegoat') scapegoatbtn!: ElementRef;

  event:any = {};
  switch_model:any = {};
  general_model:any = '';
  general_option:any[] = [];

  display_value:any[] = [];

  template:any = '';
  componentClass:any = '';
  imageComponentClass:any = {};
  dropdowns: any = {};
  custom_filter: any = {};
  show_dropdowns: boolean = false;
 
  show: any = false;
  display_table: boolean = true;
  total_value: number = 0;
  selected_context: any = {}
  target_context: any = {}
  target_element: any = {}
  open_comments: boolean = false
  parse_comments: any ={}
  context_item: any = [];
  trigger_change_obj: any;

  // sortfield: any = 'id';
  // sortorder: any = 1;
  defaultDate: any = new Date();


  // identify if the table is loaded
  table_loaded: boolean = false;
  view_loaded: boolean = false;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer:Renderer2,
    private container: ViewContainerRef,
    public commonservice: CommonService,
    public configservice: ConfigService,
    public globalservice: GlobalService,
    private changeDetectorRef: ChangeDetectorRef
    ) {
      

   }

   set show_table(data:any){
     if(!data){
       this.no_data_msg = 'You\'re not allowed to view this project.';
       this.value = [];
       this.show = false;
     }else{
       this.show = true;
       this.no_data_msg = 'No records found.';
     }
     
   }

   ngAfterContentInit(){

    // console.log('default_filter', this.default_filter);

    if(this.default_filter){
      setTimeout(()=>{
        let dt:any = this.i95table
        let reload:boolean = false
        for(let f of this.fields){
          if(this.default_filter[f.Id]){
            if(this.default_filter[f.Id].value){
              reload = true;
              dt.filters[f.Id] = this.default_filter[f.Id]

              if(this.default_filter[f.Id].value && f.Type=='date')
                this.custom_filter[f.Id] = new Date(this.default_filter[f.Id].value)
            }

          }

        }
        if(reload)
          this.getList(this.event)
      }, 1000)
  
    }

  }
   
   ngOnChanges() {


    
    if(this.appScope?.addtionalOptions){
      for(let o in this.appScope.addtionalOptions){
        this.reference[o] = this.appScope.addtionalOptions[o];
      }
    }

    // console.log('reference',this.reference);
    // console.log('reference2', this.appScope.addtionalOptions)
    // //console.logog('changed', this.fields, this.value);
    

    // this.display_value = { ...this.value}
    this.display_value = [];

    // (async()=>{





      for(let v of this.value){
        let data:any = {rowClass:'', data:[]}
        let rowData:any = v;
        let row:any = {entity:{}};
        row.entity = rowData;


        // clss += (this.selectedItm(row.entity)) ? ' item-selected' : '';
        // clss += (row.entity.disable_select) ? ' disable-select' : '';

        if(this.appScope?.rowClass){
          // //console.logog(this.appScope.rowClass);
          // //console.logog(row.entity);
          
          let rowclassObject:any = (eval('('+this.appScope.rowClass+')' ));

          //console.logog(rowclassObject);


          
          for(let c in rowclassObject){
            if(rowclassObject[c])
            data.rowClass += c+' ';
          }
        }

            
        for(let f of this.fields){
            let COL_FIELD:any = (v[f.Id]) ? v[f.Id] :'';
            let val:any = COL_FIELD
            let clss:string = '';
            let style:string = '';
            let cmmnts:any = false;
            let btnclss:any[] = [];
            
            if(f.cellStyle){
              style = eval(`this.${f.cellStyle}`)
            }

            if(f.Type=='buttons'){

              for(let b in f.buttons){
                let btclss:string = f.buttons[b].class;

                if(f.buttons[b].classList){
                  for(let c of f.buttons[b].classList){
                    btclss +=' p-button-'+c;
                  }
                }

                if(f.buttons[b].ngClass){
                  let ngClassObject:any = (eval('('+f.buttons[b].ngClass+')' ));
                  for(let c in ngClassObject){
                    if(ngClassObject[c])
                      btclss +=' '+c;
                  }
                }

                let disabled:any = false;
                if(f.buttons[b].disabled){
                  disabled = eval(f.buttons[b].disabled);
                }
                let tooltip: string = (f.buttons[b].tooltip) ? f.buttons[b].tooltip : f.buttons[b]?.label;

                btnclss.push({disabled: disabled, class:btclss, tooltip:tooltip});
              }
            }

            // //console.logog('commnt',this.checkComment(f.Id, rowData?.comment_data))
            if(this.checkComment(f.Id, rowData?.comment_data)){
              cmmnts = JSON.parse(rowData?.comment_data)[f.Id];
            }
  
            if(f.Type=='select2' && f.OptionLabel!=f.OptionValue){
              val = this.getOptionValue({col:f, data_list:rowData});
            }


            if(f.Type=='user'){


            
              val = this.commonservice.userName(COL_FIELD);
            }

            if(f.Type=='azure-user-multiple'){
              val = this.getAzureUsersDisplayName(COL_FIELD);
            }
  
            if(f.Type=='azure-user'){
              val = this.getAzureDisplayName(COL_FIELD);
            }
  
            if(f.Type=='date'){
              let format = (f.Format) ? f.Format : 'MM/DD/Y'
              if(COL_FIELD!='0000-00-00' && COL_FIELD!=null && COL_FIELD)
                val = moment(new Date(COL_FIELD),format).utc().format(format)
              else
                val = '';
            }
  
            if(f.Type=='switch'){
              if(String(f.trueValue.value)==String(COL_FIELD))
                val='✔'
              else
                val=''
            }

            if(f.Type=='images'){
              val = []

              let parse_images:any = [];
              if(COL_FIELD)
                parse_images = JSON.parse(COL_FIELD);
 
              if(parse_images?.thumbnail){
                val = parse_images.thumbnail;
              }
            }

            if(f.Type=='image'){
              val = []

              if(COL_FIELD){
                val = COL_FIELD;
              }
            }

            if(f.Type=='buttons'){
              clss  = 'text-center ';
            }


            if(f.Type=='textarea'){
              clss = 'txt-area '
            }
  
            if(f.Type=='color'){
              if(COL_FIELD)
                val=''
                style += ' background-color: '+COL_FIELD
                clss +=' color-container'
  
            }
  
            
            
            
            if(f.cellTemplateValue){
              val = eval(`this.${f.cellTemplateValue}`)
            }
            if(f.cellTemplateClass){
              let class_object:any = (eval('('+f.cellTemplateClass+')' ));
              
              for(let c in class_object){
                if(class_object[c])
                  clss += c+' ';
              }
            }
            if(f.cellClass){
              clss += f.cellClass
            }
            if(f.innerClass){
              clss += f.innerClass
            }
  
            data.data[f.Id] = {};
            data.data[f.Id].value = val;
            data.data[f.Id].class = clss;
            data.data[f.Id].style = style;
            data.data[f.Id].comments = cmmnts;
            data.data[f.Id].btn_class = btnclss;
        }
        this.display_value.push(data);
      }
    // })();

  
      // console.log(this.display_value);
  
  }

  ngOnInit(): void {
      // //console.logog(this.hello);
      // this.renderer.setProperty(this.hello.nativeElement,'innerHTML',"Hello {{te}} Angular")
      // //console.logog(this.renderer);
      // //console.logog(this.appScope);

      if(this.general_filter.length > 0){
        this.table_height -= 60;
      }

      this.commonservice.loadCommon('users',(err: any, result: any) => {
        // //console.logog(this.commonservice.data.users);
      });
      
      this.template = '<div>hello {{name}}</div> <button (click)="test()">Test Event</button>';

      this.custom_filter = {};
      // this.container.clear();
      // this.container.createEmbeddedView(this.templateref, {item: {name: 'John'}});
      for(let f of this.fields) {
        if(f.Options) {
          this.dropdowns[f.Id] = f.Options;
        }
        // set the default sorting order
        if(f.defaultSort){
          this.sortfield = f.Id;
          if(f.defaultOrder){
            if(f.defaultOrder.toLowerCase()=='desc'){
              this.sortorder = -1;
            }else{
              this.sortorder = 1;
            }
          }
        }

      }

      this.imageComponentClass = {
        commonservice: this.commonservice
      };

      this.trigger_change_obj = this.triggerFunction.subscribe(data => {
        this.triggerChange(data);
      });


      if(this.commonservice.is_mobile)
        this.getList(this.event)

  }

  setHardStyle(){
    return 'table{width:100px !Important;}'
  }


  triggerChange(data:any){
    // trigger change code category [focusitem, reinitialize]
    switch(data.type) {
      case 'focusitem':
        // code block
        setTimeout(()=>{
          let citem:any = this.cellitem
          //click the item number index of the cell
          citem._results[data.data].nativeElement.click()
        }, 500);
      break;
      
      case 'clearselect':
          this.selected_item = [];
      break;

      case 'change':
        this.ngOnChanges();
      break;
      
      case 'reinitialize':
          this.reInitializeView();
      break;
      // case 'reinitialize':
      //     this.reInitializeTable(data.data);
      // break;
      case 'addnew':
        this.value.unshift(data.data);

        // if(this.value.length<=100)
          this.display_table = false;
        
        
        setTimeout(()=>{


          this.display_table = true;

          setTimeout(()=>{
            let citem:any = this.cellitem
            citem._results[0].nativeElement.click()
           }, 500);
        }, 500);
      

      break;
      case 'updaterow':
          this.updateRow(data.data, data.rowindex);
      break;

      default:
        // code block
    }

  }

  reInitializeTable(total:number=0){
    let is_virtual:boolean = (total>100) ? true : false;
    let is_last_virtual:boolean = (this.total_value>100) ? true : false;
    if(is_virtual!=is_last_virtual){
      this.display_table = false;
      setTimeout(()=>{
        this.display_table = true;
      }, 500)
    }
    this.total_value = total;
  }
  reInitializeView(){


      this.display_table = false;
      this.show_dropdowns = false;
      setTimeout(()=>{
        this.display_table = true;
        setTimeout(() => {
          this.show_dropdowns = true;
        }, 1500);

      }, 500)
  }
  updateRow(new_data:any = {}, rowindex:number){
    // //console.logog(new_data, rowindex)
    // this.value[rowindex] = new_data;
    // this.display_table = false;
    // setTimeout(()=>{
    //   this.display_table = true;
    // }, 100)

  }
  op_comment:any
  openContext(op:any, evnt:any):any{    

    if(this.disableContext){
      return false;
    }

    
    let getpagefirst:number = (this.event.first) ? this.event.first : 0;
    this.selected_context.index = this.selected_context.index;
    this.appScope.selected_data = this.selected_context
    this.appScope.target_context = evnt

    this.context_item = [
      {
        label: 'Copy', 
        icon: 'pi pi-copy', 
        command: () => {
            //console.logog(this.selected_context);
        }
      },
  
      {
        label: 'Comments', 
        icon: 'pi pi-comments', 
        command: () => {
            if(this.selected_context.data.comment_data){
              let data = JSON.parse(this.selected_context.data.comment_data);
              this.parse_comments = data;
            }else{
              this.parse_comments = {};
            }
            setTimeout(() =>{
              this.op_comment.toggle(this.target_context);
            }, 100);
            setTimeout(() =>{
              this.comments.nativeElement.scrollTop = this.comments.nativeElement.scrollHeight
            }, 500);
        }
      },
      {
        label: 'Remove Comments', 
        icon: 'pi pi-times', 
        command: () => {

          let getpagefirst:number = (this.event.first) ? this.event.first : 0;
          var send_data:any = {
            field: 'comment_data',
            rowdata: this.selected_context.data,
            new_value: '',
            rowindex: this.selected_context.index
          };
          // //console.logog(send_data);
          this.inLineChange.emit(send_data);

        }
      },

    ];


    // set additional context menu by column
    if(this.selected_context.col.additionalMenu){
      for(let a of this.selected_context.col.additionalMenu){
        this.context_item.push(a);
      }
    }

    //set additional context menu set in appScope to adopt the objects from the parent component
    if(this.appScope.additionalContextMenu){
      let addtional_cm:any = this.appScope.additionalContextMenu[this.selected_context.col.Id];
      if(addtional_cm){
        for(let a of addtional_cm){
          this.context_item.push(a);
        }
      }
    }


    this.target_context = evnt;
    this.op_comment = op;
    // alert();
  }

  all_comments:any=[]
  show_all_comments: boolean = false;
  showComments(col:any, data:any, evnt:any):boolean{

    let cpanel:any = this.commentpanel;

      let parse_data:any = []
      let all_comments:any = [];
      this.show_all_comments = false;
      cpanel.hide();
      if(data.comment_data!=''){
        parse_data = JSON.parse(data.comment_data);
        if(parse_data){
          if(parse_data.hasOwnProperty(col.Id)){
            this.all_comments =  parse_data[col.Id];

            if(this.all_comments.length>0){
              this.show_all_comments = true;
              // setTimeout(()=>{
                if(this.show_all_comments && !this.editing){
                  cpanel.show(evnt)    
                }

              // }, 500);

              
            }
          }
        }
      }
      // //console.logog(all_comments);
      return true;
  }
  showComments2(data:any, evnt:any):boolean{

      this.show_all_comments = false;
      let cpanel:any = this.commentpanel;
      cpanel.hide()

      if(data){

        if(this.all_comments.length>0){
       
        }
        
          this.all_comments =  data;
          if(this.all_comments.length>0 && !this.editing){

                setTimeout(()=>{
                  cpanel.show(evnt)
                }, 100);     
     
          }
      }
      // //console.logog(all_comments);
      return true;
  }


  comment_content: any = ""
  addComment(){
    let data:any = {};
    if(this.comment_content){
      data = { user: this.configservice.userid, datetime: moment().zone(0).format('Y-MM-DD: HH:mm:ss'), comment: this.comment_content };
      if(!this.parse_comments[this.selected_context.col.Id]){
        this.parse_comments[this.selected_context.col.Id] = [];
      }
      this.parse_comments[this.selected_context.col.Id].push(data);
      this.comment_content = '';

      // //console.logog(this.parse_comments);
      let new_data = JSON.stringify(this.parse_comments);
      let getpagefirst:number = (this.event.first) ? this.event.first : 0;
      var send_data:any = {
        field: 'comment_data',
        rowdata: this.selected_context.data,
        new_value: this.parse_comments,
        rowindex: this.selected_context.index
      };
      // //console.logog(send_data);
      this.inLineChange.emit(send_data);
      setTimeout(() =>{
        this.comments.nativeElement.scrollTop = this.comments.nativeElement.scrollHeight
      }, 500);

    }

  }



  rowClass(row:any={}){
    let clss = '';



    if(this.appScope.rowClass){
      let rowClass:any = this.appScope.rowClass.split(',');
      for(let r of rowClass){
        let p:any = r.split(':');
        let con:any = (p[1]) ? p[1] : 'false';


        // //console.logog('rowclass',con.replace('|',','));
        // //console.logog('rowclass',con);

        if(eval(con.replace('[|]',','))){
          let ngclass = (p[0]) ? ' '+p[0].replaceAll(`'`,``) : '';
          clss += ngclass;
        }
      }
    }
    clss += (this.selectedItm(row.entity)) ? ' item-selected' : '';
    clss += (row.entity.disable_select) ? ' disable-select' : '';
    return clss;
  }

  imgUrl(id:any=0){
    let img_url:any = this.configservice.appConfig.apiurl+'upload/file/thumbnail/'+id;
    return img_url;
  }
  imageData(data:any='', field_name:any=''){
    if(data!=null && data!=''){
      let new_data:any = JSON.parse(data);
      // //console.logog(new_data);
      let html:string = '';
      if(new_data.thumbnail){
        for(let i of new_data.thumbnail){
          html +='<img (click)="commonservice.openFile('+i+')" src="'+this.configservice.appConfig.apiurl+'upload/file/thumbnail/'+i+'"/>';
        }
      }
      return html;
    }else{
      return '';
    }

  }

  setCellELement(e:any,rowdata:any={},col:any={},rowIndex:number){
      this.target_element = e;
      if(this.appScope.onClickCell){
        let cellvalue = {rowdata:rowdata,col:col.Id,rowindex:rowIndex}
        this.appScope.onClickCell(cellvalue);
      }
  }
  allowed_edit_item:any=[];
  retstrictionEdit(e:any){
    // if(!this.allowed_edit_item.includes(e.data)){

      this.appScope?.beforeEdit(e, (r:any)=>{
        if(r){
          this.allowed_edit_item.push(e.data);
          setTimeout(()=>{
            this.target_element.click()
          }, 100)
        }else{
            this.scapegoatbtn.nativeElement.click();
        }
      }); 
    // }
  }


  editing:boolean = false;
  onEditInit(e:any){    
    this.editing = true;
    let cpanel:any = this.commentpanel;
    cpanel.hide();

    if(this.appScope?.beforeEdit){
      this.retstrictionEdit(e);
    }
    
    // for options
    if(e.field.Type=='select2' || e.field.Type=='select-multiple'){
      // //console.logog(e);
      let opts:any[] = this.getOptions(e.field);
      if(e.field.filterByfield && e.data){
        let newoption:any[] = [];
        for(let o of this.appScope.addtionalOptions[e.field.Id]){
          if(e.data[e.field.filterByfield]==o[e.field.filterByfield]){
            newoption.push(o);
          }
        }
        opts = newoption;
      }
      this.general_option = opts;

    }



    let value:any = e.data[e.field.Id];
    if(e.field.Type=='date'){
      if(value=='0000-00-00' || !value){ 
        this.general_model = '';
      }else{
        this.general_model = new Date(value);
      }
    }else if(e.field.Type=='select-multiple'){
      if(this.isJsonString(value)){
        this.general_model = JSON.parse(value);
      }else{
        this.general_model = [];
      }

    }else{
      this.general_model = e.data[e.field.Id];
    }

  }
  onEditComplete(e:any):boolean{
    this.editing = false;

    if(this.appScope?.afterEdit){
      this.appScope.afterEdit();
    }
 

    let value:any = e.data[e.field.Id];
    let changed = false;
      
    if(e.field.Type=='select-multiple'){
      // //console.logog('here',this.general_model, value)
      
      if(this.isJsonString(value)){
        if(JSON.stringify(this.general_model)!=value){
          this.general_model = JSON.stringify(this.general_model)
          changed = true;
        }
      }else{
        changed = true;
        this.general_model = JSON.stringify(this.general_model)
      }



    }else if(e.field.Type=='date'){
      if(value=='0000-00-00' || !value){ 
        value = '';
      }else{
        value = new Date(value);
      }
      let format_model = moment(this.general_model).format('Y-MM-DD');
      let format_value = moment(value).format('Y-MM-DD');
      if(format_model!=format_value){
        changed = true;
      }
      this.general_model = moment(this.general_model).format('Y-MM-DD');
    }else{
      if(this.general_model!=value){
        changed = true;
      }
    }

    let getpagefirst:number = (this.event.first) ? this.event.first : 0;
    if(changed){
      var send_data:any = {
        field: e.field.Id,
        field_data: e.field,
        rowdata: e.data,
        new_value: this.general_model,
        rowindex: (e.index)
      };
      
      this.inLineChange.emit(send_data);
    }

    return true
  }

  dateFormat(data:any, config: any){
    let strdate = '';
    if(data=='0000-00-00' || !data){

    }else if(config.Format){
      strdate = moment(data).utc().format(config.Format);
    }else{
      strdate = moment(data).utc().format('MM/DD/YYYY');
    }

    return strdate;
  }

  dateFormatTZ(data:any, config: any){
    let strdate = '';
    let tz = moment().zone();
    if(data=='0000-00-00' || !data){

    }else if(config){
      strdate = moment(data).zone(tz).format(config);
    }else{
      strdate = moment(data).zone(moment().zone()).format('MM/DD/YYYY');
    }

    return strdate;
  }


  getAzureDisplayName(userData:any){
    if(userData && userData != '' && userData != null){
      if(this.isJsonString(userData)){
        let user = JSON.parse(userData);
        if(user){
          return user.displayName;
        }

        return '';
      }
    }
    return '';
  }

  isJsonString(str:string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  getAzureUsersDisplayName(userData:any){
    if(userData && userData != '' && userData != null){
      let users = JSON.parse(userData);
      let arrayNames:any = [];
      if(typeof users === "object" && !Array.isArray(users) && users !== null){
        let _users = [users];
        if(_users.length > 0){
          _users.forEach((user:any) => {
            arrayNames.push(user.displayName);
          });
  
          return arrayNames.join(', ');
        }
      }else{
          if(Array.isArray(users)){
            if(users.length > 0){
              users.forEach((user:any) => {
                arrayNames.push(user.displayName);
              });
      
              return arrayNames.join(', ');
            }
          }
        }
    }
    
    return '';
  }

  selectedItm(data:any):boolean {
    // //console.logog(data);
    // if(this.globalservice.getData(this.globalservice))
    
    return (this.selected_item.includes(data));

  }

  header_checked:boolean = false;
  checkAll(e:any){
    // //console.logog(this.selected_item)

    let total_allowed = 0;
    for(let d of this.value){
      if(!d.disable_select){
        total_allowed++;
      }
    }

    if(this.selected_item.length!=total_allowed){
      // this.selected_item = this.value;
      this.selected_item = [];
      for(let d of this.value){
        if(!d.disable_select){
          this.selected_item.push(d);
        }

      }
    }else{
      this.selected_item = [];
    }
    this.onRowSelect({});
  }
  onRowSelect(e:any){
    let total_allowed = 0;
    for(let d of this.value){
      if(!d.disable_select){
        total_allowed++;
      }
    }

    if(this.selected_item.length!=total_allowed){
      this.header_checked = false;
    }else{   
      this.header_checked = true;
    }

    this.itemChecked.emit(this.selected_item);
  }

  getList(e:any, tbl:any=''):boolean {

    this.selected_item = [];
    // this.virtual_scroll = true

    // setTimeout(() => {
    //   (this.rows>200) ? this.virtual_scroll = true : this.virtual_scroll = false;
    // }, 200);

    
    if(this.table_loaded && tbl!=''){
      return false;
    }
    this.table_loaded = true;
    e.rows = this.rows;
    
    
    
    this.event = e;
    this.selected_item = [];
    this.header_checked = false;
    // //console.logog(this.event);
    if(!this.refreshing_table){
      this.onLazyLoad.emit(e);
    }
    setTimeout(() => {
      this.refreshing_table = false;
    }, 200);
    return true;
  }
  // prevent from multiple call when refreshing.
  refreshing_table: boolean = false;
  refreshTable(){
    this.refreshing_table = true;
    let tbl:any = this.i95table;
    tbl.clear();

    this.filter.clear();
    this.custom_filter = {};
    setTimeout(() => {
      this.refreshing_table = false;
      
      setTimeout(() => {
        // alert()
        this.event.first = 0;
        this.event.sortField = this.sortfield;
        this.event.sortOrder = this.sortorder;
        this.getList(this.event);
      }, 100);
    }, 100);

  }

  filterChange(field:any='', value:any) {

    if(field.Type=='date') {
      this.custom_filter[field.Id]=value;
      if(value!=''){
        value = moment(value).format('Y-MM-DD');
      }
    }
    // //console.logog(this.custom_filter);

    this.event.filters[field.Id].value = value;
    this.getList(this.event);
  }

  openMenu(data:any = {},e:any = {}){
      this.openItemMenu.emit({data:data, event:e});
  }

  switchValue(data:any){
    return data;
  }
  switchChanged(col:any,data:any, rindex:number): boolean {
    if(!col.enableCellEdit || !this.allowEdit){
      return false;
    }

    let truevalue = col.trueValue.value;
    let falsevalue = col.falseValue.value;
    // data[col.Id]= 1;
    let new_value = truevalue;
    if(data[col.Id]==truevalue){
      new_value = falsevalue;
    }

    let getpagefirst:number = (this.event.first) ? this.event.first : 0;
      var send_data:any = {
        field: col.Id,
        field_data: col,
        rowdata: data,
        new_value: new_value,
        rowindex: rindex
      };
      this.inLineChange.emit(send_data);
      
      return true;

  }
  colorChanged(col:any,data:any, evnt:any, rindex:number): boolean {
    //console.logog('event',evnt);
    if(!col.enableCellEdit || !this.allowEdit){
      return false;
    }
    let getpagefirst:number = (this.event.first) ? this.event.first : 0;
    if(data[col.Id]!=evnt.value){

        var send_data:any = {
          field: col.Id,
          field_data: col,
          rowdata: data,
          new_value: evnt.value,
          rowindex: rindex
        };
      this.inLineChange.emit(send_data);
    }

      return true;

  }

  allowShowField(field:any=''){
    var ret = true;
    if(this.seemore_fields){
      ret = this.seemore_fields.includes(field);
    }
  
    return ret;
  
  }

  setSwitchFilterOption(col:any){


  }
  
  getOptions(col:any){


    let options:any[] = []
    let assigned:boolean = false;



    if(this.appScope?.addtionalOptions){
      // let ref1:any = (this.appScope.addtionalOptions[col.reference]) ? this.appScope.addtionalOptions[col.reference] : [];
      if(this.appScope.addtionalOptions[col.reference]){

        return this.appScope.addtionalOptions[col.reference];        
      }
      if(this.appScope.addtionalOptions[col.Id]){
        return this.appScope.addtionalOptions[col.Id];
      }
    }

    
    let opts = (col.Options) ? col.Options : []

    if(col.reference){  
      return (this.reference[col.reference]) ? this.reference[col.reference] : opts;
    }else{
      return (this.reference[col.Id]) ? this.reference[col.Id] : opts;
    }

    return [];

  }
  



  getOptionValue(col:any){

    let type:string = col.col.Type;

    if(!col.data_list[col.col.Id]){
      return '';
    }
    if(col.col.OptionLabel!=col.col.OptionValue){
      let opt_data:any = [];

        if(this.appScope.addtionalOptions){
          if(this.appScope.addtionalOptions[col.col.Id]){
            opt_data = this.appScope.addtionalOptions[col.col.Id];
          }
        }else if(col.reference){
          opt_data = (this.reference[col.reference]) ? this.reference[col.reference] : col.Options;
        }

        let value = '';

        if(opt_data){
          let value_data:any = this.globalservice.getData(opt_data, col.col.OptionValue, col.data_list[col.col.Id]);

          if(value_data){
            value = (value_data[col.col.OptionLabel]) ? value_data[col.col.OptionLabel] : '';
          }
          
        }

    
        return (value) ? value : '';

    }

    if(type=='select-multiple'){
      if(this.isJsonString(col.data_list[col.col.Id]))
        return (JSON.parse(col.data_list[col.col.Id])).join(', ')
      else
        return (col.data_list[col.col.Id]) ? col.data_list[col.col.Id] : '';
    }

     
      return (col.data_list[col.col.Id]) ? col.data_list[col.col.Id] : '';

  }
  columnStyle(col:any={}){
    let style = "";
    let colwidth:number = 0;
    if(col.width){
      colwidth = Number(col.width.replace('px',''));
    }else{
      colwidth = 120;
    }
    if(col.resize_width){ 
      colwidth = col.resize_width;
    }

    
    style += "width: "+colwidth+"px;";
    // style += "flex: 1 1 "+colwidth+"px !important;";

      
    if(col.frozen){
      style += "position: sticky;";
      style += "min-width: "+colwidth+"px;";
      style += "max-width: "+colwidth+"px;";

      let fields_used: any = [];

      if(!this.seemore_fields)
        fields_used = this.fields;
      if(this.seemore_fields){
        for(let f of this.fields){
          if(this.seemore_fields.indexOf(f.Id)>=0)
            fields_used.push(f)
        }
      }

      // identify the last column of frozen from left
      if(col.alignFrozen=='right'){
        // style +='right:80px;';
        let border_left:any = true;
        let num:number = 0;
        for(let f of fields_used){
          if(f.frozen && f.alignFrozen=='right'){
            if(num==0 && f.Id==col.Id){
              style += "border-left: 1px solid #bbbbbb !important;";
            }
            num++;
          }
        }
        // //console.logog('reverse_loop',this.fields.slice().reverse())
      }
      if(col.alignFrozen=='left'){



        let cindex: any = this.globalservice.getDataIndex(fields_used, 'Id', col.Id);
        if(fields_used[cindex+1]){
          if(fields_used[cindex+1].alignFrozen!='left'){
            style += "border-right: 1px solid #bbbbbb !important;";
          }
        }
      }
  

      
    }

    if(col.styleTemplate){
      // style +=col.styleTemplate;
    }
    // //console.logog(col.Id,style);
    return style;

  }
  
  onResizeColumn(evnt:any){
    // //console.logog('column', evnt)
    // alert();
    if(evnt.element.accessKey){
      let col:any = this.globalservice.getData(this.fields, 'Id', evnt.element.accessKey);
      if(col){
        col.resize_width = evnt.delta;
      }
      // alert(evnt.delta);
    }
    // //console.logog(this.fields);
      this.changeDetectorRef.detectChanges();
    
    // alert();
  }
  testCol(evnt:any){

    //console.logog('column', this.selected_column)
    //console.logog('test', evnt)
    // alert();
  }
  chnage(){
    this.componentClass = {
      name: 'wow!',
      onEvent: () => { 
          this.componentClass.name = 'world';

       }
    };
  }

  page:number = 1;
  get pageValue(): number { return this.page; }
  set pageValue(data: number) {
    this.page = Number(data);
    this.changePage();
  }
  totalPage(arr:boolean = false) {
    let total_page: number = Math.ceil(this.totalRecords/this.event.rows);
    let ret:any; 
    if(arr){
      ret = [];
      for (let i = 1; i <= total_page; i++) {
        ret.push(i);
      }
    }else{
      ret = total_page;
    }
    
    return ret;
  }
  onChangePage(page:any=1){
    //console.logog(this.page);
    //console.logog(page);
  }
  changePage(action:any=""){
   
    if(action=='+'){
      this.page +=1;
      this.selected_item = [];
    }else if(action=='-'){
      this.page -=1;
      this.selected_item = [];
    }else{

    }
    let multiplier = (this.page-1);
    let frst = multiplier*this.event.rows;
    // //console.logog(frst);
    this.event.first = frst;

    setTimeout(() => {
      this.getList(this.event);
      
    },300);
  }

  
  filter:any = {
    main: this,
    searching:'',
    value: {},
    data: {search:'', filter:{}},
    search: '',
    clear(){
      this.value = {};
      this.search = '';
      // this.main.refreshing_table = false;
      this.data = {search:'', filter:{}};
      this.main.filterChanged.emit(this.data);
    },
    refresh(){
      this.main.filterChanged.emit(this.data);
    },
    inDate(field:any, value:any){
      let sdate = moment(`${value.year}-${value.month+1}-${value.day}`).format('YYYY-MM-DD');
      // //console.logog(sdate);
      let options = [];
      let filter_options: any = (field.inGeneralFilter.options) ? field.inGeneralFilter.options : [];
      for(let o of filter_options){
        options.push(moment(`${o}`).format('YYYY-MM-DD'))
      }
      return options.includes(sdate);
    },
    executeSearch(text:string=''){
      this.data.search = text;   
      this.main.filterChanged.emit(this.data);
    },
    changeSearch(event:any){
      
      clearTimeout(this.searching);
      
      this.searching = setTimeout(() => {
        this.executeSearch(event.target.value);
      }, 1000);
      // this.main.filterChanged.emit(this.data);
    },
    change(field:any='', value:any){
      this.value[field.Id] = value;
      let check_value = JSON.parse(JSON.stringify(this.value));
      if(field.Type=='date') {
        if(value!=''){
          value = moment(value).format('Y-MM-DD');
        }
        check_value[field.Id] = value;
      }


      this.data.filter = check_value;
      this.main.filterChanged.emit(this.data);
    }

  }

  checkComment(field:any='', data:any=false){

    if(this.disableContext){
      return false;
    }
      if(!data){
        return false;
      }

      let commentjson = JSON.parse(data);
      
      if(!commentjson[field]){
        return false;
      }




    return true;
  }
  onPageChange(evnt:any){
    this.rows = evnt.rows;
    this.event.first = evnt.first;
    this.getList(this.event);
  }
  onChange(evnt:any, type:string=''){
    //console.logog(evnt);
    // //console.logog(this.event);
    if(type=='filter')
      this.event.filters = evnt.filters;
      this.event.first =0;
      
    if(type=='sort'){
      this.event.sortField = evnt.field;
      this.event.sortOrder = evnt.order;
    }
    this.getList(this.event);
  }
  
  changeWheelSpeed(container:any, speedY:number) {
    var scrollY = 0;
    var handleScrollReset = function() {
        scrollY = container.scrollTop;
    };
    var handleMouseWheel = function(e:any) {
        e.preventDefault();
        scrollY += speedY * e.deltaY
        if (scrollY < 0) {
            scrollY = 0;
        } else {
            var limitY = container.scrollHeight - container.clientHeight;
            if (scrollY > limitY) {
                scrollY = limitY;
            }
        }
        container.scrollTop = scrollY;
    };

    var removed = false;
    container.addEventListener('mouseup', handleScrollReset, false);
    container.addEventListener('mousedown', handleScrollReset, false);
    container.addEventListener('mousewheel', handleMouseWheel, false);

    return function() {
        if (removed) {
            return;
        }

        container.removeEventListener('mouseup', handleScrollReset, false);
        container.removeEventListener('mousedown', handleScrollReset, false);
        container.removeEventListener('mousewheel', handleMouseWheel, false);
        removed = true;
    };
}


imgThumbnail(d:any){
  // //console.logog('dd',d);
  let thumbnails:any = []
  if(this.globalservice.isJsonString(d)){
    let parse_data:any = JSON.parse(d);
    // //console.logog('parse',parse_data);
    // http://localhost:3000/api/upload/file/thumbnail/37

    if(parse_data.thumbnail){
      for(let t of parse_data.thumbnail){
        thumbnails.push(this.configservice.base_api_url+'api/upload/file/thumbnail/'+t);
      }
    }
  }

  return thumbnails;
}


removeTags(str:any) {
  if ((str === null) || (str === ''))
      return false;
  else
      str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/ig, '');
}

ngAfterViewInit(): void {
  // const el:any = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
  // this.changeWheelSpeed(el, 0.8);
  // //console.logog(el);
  // el.onscroll = function(e:any, d:any){
  //   //console.logog(e, d);
  // };

  // el.onscrollend = function(e:any, d:any){
  //   alert();
  // };


  // //console.logog(el);
  // setTimeout(() => {
    this.view_loaded = true;
  // }, 1500);
  // //console.logog(el);
    setTimeout(() => {
      this.show_dropdowns = true;
    }, 1500);

}

rowDoubleClick(value:any){
  // console.log("sss",value);
  this.triggerDoubleClick.emit(value);
}

tableHeight(type:string='height'):string{
  let total_height:any = 300;



  if(this.reduce_height){
    total_height = `calc(100vh - ${this.reduce_height}px)`

    if(type=='scroll'){
      total_height = `calc(100vh - ${this.reduce_height+50}px)`
    }
  }else{
    if(this.table_height){
      total_height = this.table_height;
    }
    if(type=='scroll'){
      total_height -=50;
    }
    total_height = String(total_height)+'px';
  }
  

  return total_height;
}

editKeyDown(evnt:any){
  if(evnt.ctrlKey && evnt.code=='KeyV'){
    setTimeout(()=>{
      let loader:any = this.document.getElementById("initial-loader")
      loader.click();

    },200)

  }
}
filterIndicator(col:any):any{

  let filter:any = this.event?.filters;

  if(filter==undefined)
    return 0

  let filter_field:any = filter?.[col.Id];

  if(!filter_field)
    return 0

  if(col.multiFilter && filter_field.value){
    if(typeof(filter_field.value)=='object')
      return filter_field.value.length;
    else
      return 0;
    
  }else if(filter_field.value){
    return 1;
  }
    
  return 0;
} 

onScrollMobile(event: any) {

  // visible height + pixel scrolled >= total height 
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    // console.log("End", this.totalRecords);

    // console.log();

    if(this.value.length<this.totalRecords){
      this.loading = true;
      setTimeout(()=>{
        event.target.scrollTop = event.target.scrollHeight
      }, 100);

    }


  }
}

dropDownType(col:any):string{
  // console.log(col);
  // if(col?.multiFilter)
  //   return 'multiple'

  return 'dropdown'
}

}
