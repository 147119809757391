<p-dropdown
    [placeholder]="placeholder" 
    [options]="userList()"
    [filter]="true"
    filterBy="full_name"
    [(ngModel)]="user_model"
    [styleClass]="styleClass"
    [resetFilterOnHide]="true"
    [showClear]="showClear"
    optionValue="id"
    [disabled]="disabled"
    [virtualScroll]="true" 
    [itemSize]="10"
    [style]="{'width':'100%'}"
    panelStyleClass="user-default-dropdown"
    (onChange)="changeValue($event)"
    [appendTo]='appendTo'
    *ngIf="Type=='dropdown'"
>

  <!-- user profile image template -->
  <ng-template #userprofilepicture let-profile>

    <div style="position: relative;display: inline-block;">
        <p-avatar
            [label]="commonservice.acronym(profile.user_data.first_name+' '+profile.user_data.last_name)"
            styleClass="p-mr-2"
            [size]="profile.size ? profile.size : 'small'"
            shape="circle"
            *ngIf="!profile.user_data.userphotofile"
            [style]="{'background-color': 'rgb(88 114 152)', 'color': '#ffffff'}"
        ></p-avatar>
        <p-avatar
            styleClass="p-mr-2"
            [size]="profile.size ? profile.size : 'small'"
            shape="circle"
            *ngIf="profile.user_data.userphotofile"
            [image]="profile.user_data.userphotofile"
            [style]="{'background-color': 'rgb(88 114 152)', 'color': '#ffffff'}"
        ></p-avatar>
    </div>

</ng-template>


<!-- (onFilter)="onFilterTextChanged($event.filter)"
(onFocus)="setUserOption()" -->

<ng-template pTemplate="input">
<input type="text" pInputText class="inputfield w-full" value="test">
</ng-template>

<ng-template pTemplate="selectedItem">
    
<div *ngIf="user_model">
    {{commonservice.userName(user_model)}}
</div>
<div *ngIf="!user_model && user_data">
    {{commonservice.userName(user_data)}}
</div>

</ng-template>
<ng-template let-user pTemplate="item">
<div class="standard-user-item">



    <div class="standard-user-dp">
        <ng-template 
        *ngTemplateOutlet="userprofilepicture; context: {$implicit: {user_data: user, size: 'small'}}" >
    </ng-template>
    </div>
    <div class="standard-name">{{user.full_name}}</div>
</div>
</ng-template>
<ng-template let-user pTemplate="empty">
    <span *ngIf="!user_no_result">
        <i class="fa fa-spinner fa-spin"></i> Loading...
    </span>
    <span *ngIf="user_no_result">
        No Result Found.
    </span>
    
</ng-template>
</p-dropdown>


<div
    [pTooltip]="userAssignedList()"                                                                    
    tooltipPosition="bottom"
>
    
    
    <p-multiSelect
        [placeholder]="placeholder" 
        [options]="userList()"
        display="chip"
        [filter]="true"
        filterBy="full_name"
        
    [disabled]="disabled"
        [style]="{'width':'100%'}"
        [(ngModel)]="user_model"
        [styleClass]="styleClass"
        optionValue="id"
        optionLabel="full_name"
        [virtualScroll]="true"
        [itemSize]="10"
        (onChange)="changeValue($event)"
        [appendTo]='appendTo'
        *ngIf="Type=='multiple'"
    >
    
    
    <!-- (onFilter)="onFilterTextChanged($event.filter)"
    (onFocus)="setUserOption()" -->
    
    <ng-template pTemplate="input">
    <input type="text" pInputText class="inputfield w-full" value="test">
    </ng-template>
    

    <ng-template let-user pTemplate="item">
    <div class="standard-user-item">
        <div class="standard-user-dp">
            <img *ngIf="user.userphotofile" src="{{user.userphotofile}}">
            <img *ngIf="!user.userphotofile" src="/assets/img/common_avatar.svg">
        </div>
        <div class="standard-name">{{user.full_name}}</div>
    </div>
    </ng-template>

    </p-multiSelect>
</div>